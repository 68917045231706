import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

import { logDebug } from "paf-utils-loggers";

//import IndustryExperience from "./IndustryExperience";
//import TitleExperience from "./TitleExperience";
import EmployeeCoreProfile from "./EmployeeCoreProfile";

export default function EmployeeProfile(props) {
  logDebug(`In EmployeeProfile with props: ${JSON.stringify(props)}`);

  return (
    <div className="OHPProfileBody">
      <Grid container className="OHPProfileContainer">
        <Grid item className="OHPPorfileItem">
          <EmployeeCoreProfile {...props} />
        </Grid>
        {/*}
        <Grid item className="OHPValidatedItmesItem">
          <Grid container className="OHPValidatedItemsContainer">
            <Grid item className="OHPValidatedIndustriesItem">
              <IndustryExperience {...props} />
            </Grid>
            <Grid item className="OHPValidatedCertificatesItem">
              <TitleExperience {...props} />
            </Grid>
          </Grid>
        </Grid>
        {*/}
      </Grid>
    </div>
  );
}

EmployeeProfile.propTypes = {
  httpService: PropTypes.object.isRequired,
  displayMessage: PropTypes.func.isRequired,
  employeeProfile: PropTypes.object.isRequired,
  notificationCount: PropTypes.number.isRequired,
};
