import React, { useState } from "react";

import { Grid, Box, Button, Typography, TextField } from "@material-ui/core";
import PageFooter from "../framework/PageFooter";
import ohpNumber from "../../img/ohp_number.svg";
import { validateEmail } from "paf-utils-validators";
import SnackbarComponent from "paf-component-snackbar";
import HTTPService from "paf-service-httpservice";
import { logDebug } from "paf-utils-loggers";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../img/logo.svg";

import Divider from "@material-ui/core/Divider";

import config from "../../config/config.json";
import EmailResetEntity from "../../model/EmailResetEntity";

window.logLevel = config.Application.LogLevel;
logDebug("Initialized client with:");
logDebug(`window.logLevel: ${window.logLevel}`);
window.dataManagerURL = config.API.URL;
logDebug(`window.dataManagerURL: ${window.dataManagerURL}`);
window.webBasedURL = config.WEB.URL;
logDebug(`window.webBasedURL: ${window.webBasedURL}`);

export default function InitiatePasswordResetPage(props) {
  const [enterEmail, setEnterEmail] = useState(new EmailResetEntity());

  // Snackbar
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [displaySnackbar, setDisplaySnackbar] = useState(false);

  ///
  const submitEmailURL = "/api/user/initpasswordreset";
  const httpService = new HTTPService(window.dataManagerURL);
  ///

  //////////////////
  //// Snackbar ////
  //////////////////
  // display snackbar
  const displayMessage = (variant, message) => {
    setSnackbarVariant(variant);
    setSnackbarMessage(message);
    setDisplaySnackbar(true);
  };
  // hide snackbar
  const handleCloseSnackbar = (event, reason) => {
    setDisplaySnackbar(false);
  };
  //////////////////////
  //// END Snackbar ////
  //////////////////////

  const handleValueChange = (type, event) => {
    let newValue = null;
    newValue = event.target.value;
    setEnterEmail({ ...enterEmail, [type]: newValue });
  };

  const emailValidation = (enterEmail) => {
    if (!validateEmail(enterEmail.email)) {
      displayMessage("error", "Please enter valid email address");
      return false;
    }
    return true;
  };

  //submit
  const onSubmit = () => {
    if (emailValidation(enterEmail)) {
      submitEmail(enterEmail);
    }
  };

  ///////////////////////
  //// API callbacks ////
  ///////////////////////
  const onEmailSuccess = (data) => {
    // redirect to the page to enter a new pasword
    window.location.href = "/password_reset_confirmation";
  };
  const onEmailError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  ///////////////////////////
  //// END API callbacks ////
  ///////////////////////////
  ///////////////////
  //// API calls ////
  // save employer
  const submitEmail = (enterEmail) => {
    let formData = new FormData();
    formData.append("email", enterEmail.email);
    httpService.put(submitEmailURL, formData, onEmailSuccess, onEmailError);
  };
  ///////////////////////
  //// END API calls ////
  ///////////////////////

  const useStyles = makeStyles((theme) => ({
    registrationAttribute: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "300px",
      },
    },
    leftBar: {
      display: "none",

      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    title: {
      width: "280px",
      fontSize: "24px",
      [theme.breakpoints.up("md")]: {},
    },
  }));
  const classes = useStyles();

  return (
    <div className="EmployeeRegistrationPage-Body">
      <Grid container className="EmployeeRegistrationPage-BodyContainer">
        <Grid item className="EmployeeRegistrationPage-AppBarItem">
          <Box>
            <img
              className="OHPAppBar-OHPNumberLogo"
              src={ohpNumber}
              alt="One Hunderd Percent"
            />
          </Box>
        </Grid>
        <Grid
          conainer
          className="EmployeePasswordReset-PageContainer"
          // styles={{ height: "500 px" }}
        >
          <Grid
            item
            className={clsx(
              "EmployeeRegistrationPage-LeftBarItem",
              classes.leftBar
            )}
          >
            <Box>
              <img
                className="EmployeeRegistrationPage-leftBarLogo"
                src={logo}
                alt="One Hundred Percent Logo"
              />
            </Box>
          </Grid>
          <Grid
            item
            className={clsx(
              "EmployeeRegistrationPage-PageBodyItem",
              classes.container
            )}
          >
            <Grid
              container
              className="EmployeeRegistrationPage-PageBodyContainer"
            >
              <Grid item className="EmployeeRegistrationPage-RightItem">
                <Grid
                  container
                  className="EmployeeRegistrationPage-RightContainer"
                >
                  <Grid
                    item
                    className={clsx(
                      "EmployeeRegistrationPage-Title",
                      classes.title
                    )}
                  >
                    <Typography variant="h4">Forgot Password</Typography>
                    <Divider className="OHPDivider" />
                  </Grid>
                  <Grid
                    item
                    className="EmployeeRegistrationPage-RegistrationForm"
                  ></Grid>
                  <Grid item classname="EmployeeForgotPassword-Info">
                    <Typography variant="h7">
                      Please enter the Email Address you signed up with
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={clsx(
                      "EmployeeForgotPassword-TextField",
                      classes.registrationAttribute
                    )}
                  >
                    <TextField
                      id={"ForgotPasswordEnterEmail"}
                      label="Email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={(event) => handleValueChange("email", event)}
                    />
                  </Grid>

                  <Grid item className="EmployeeRegistrationPage-Button">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSubmit}
                      size="Large"
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="EmployeeRegistrationPage-PageFooterItem">
                <PageFooter></PageFooter>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SnackbarComponent
          openFlag={displaySnackbar}
          variant={snackbarVariant}
          message={snackbarMessage}
          onClose={handleCloseSnackbar}
        />
      </Grid>
    </div>
  );
}
