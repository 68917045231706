import React from "react";

import Typography from "@material-ui/core/Typography";

import { logDebug } from "paf-utils-loggers";

import logoURL from "../../img/ohp_logo.svg";

export default function RegistrationConfirmationPage(props) {
  logDebug(
    `In RegistrationConfirmationPage with props: ${JSON.stringify(props)}`
  );

  return (
    <div className="ActivationConfirmationPage-Body">
      <div className="ActivationConfirmationPage-AppLogoItem">
        <img
          className="ActivationConfirmationPage-LogoImage"
          src={logoURL}
          alt="One Hundred Percent Logo"
        />
      </div>
      <div className="ActivationConfirmationPage-ActivationResultItem">
        <Typography className="ActivationConfirmationPage-ActivationResultText">
          Thank you for submitting your information. You will receive an email from
          us with the instructions on how to complete your registration
          process.
        </Typography>
      </div>
    </div>
  );
}
