import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import CircularProgress from "@material-ui/core/CircularProgress";

// left menu icons
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";

// account menu icons
import PersonIcon from "@material-ui/icons/Person";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SettingsIcon from "@material-ui/icons/Settings";

import AuthorizedMenuItem from "paf-component-security-authmenuitem";
import SnackbarComponent from "paf-component-snackbar";

import UserTile from "./UserTile";
import PageFooter from "./PageFooter";

import appLogo from "../../img/logo.svg";
import ohpLogo from "../../img/ohp_logo.svg";
import ohpNumber from "../../img/ohp_number.svg";

import accountCircleButton from "../../img/icons/account_circle_white_24dp.svg";
import upgradeButton from "../../img/upgrade_button.svg";
import educationButton from "../../img/icons/education_blue.svg";
import certificationsButton from "../../img/icons/certifications_blue.svg";
import endorsementsButton from "../../img/icons/endorsements_blue.svg";
import logoutButtonIcon from "../../img/icons/logout_blue.svg";

import { logDebug } from "paf-utils-loggers";

import EmployeeProfileEntity from "../../model/EmployeeProfileEntity";

// Drawer states
const large = "Large";
const small = "Small";
const none = "None";
const size = "md";
const largeWidth = 250;
const smallWidth = 90;
const noneWidth = 0;

const minDrawerShowWidth = 600;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: theme.breakpoints.up(size) ? largeWidth : noneWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerLarge: {
    paddingTop: "65px",
    width: largeWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerSmall: {
    paddingTop: "65px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: smallWidth,
  },
  drawerNone: {
    paddingTop: "65px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: noneWidth,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    minHeight: "500px",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
  },
  pageContent: {
    height: "100%",
    minHeight: "calc(100vh - 144px)",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up(size)]: {
      display: "flex",
    },
    alignItems: "right",
  },
}));

export default function OHPPage(props) {
  logDebug(`In OHPPage with props: ${JSON.stringify(props)}`);

  const classes = useStyles();
  const theme = useTheme();

  const [navbar, setNavbar] = useState(
    window.innerWidth > minDrawerShowWidth ? large : none
  );

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState();
  const { httpService, content } = props;
  const [employeeProfile, setEmployeeProfile] = useState(
    new EmployeeProfileEntity()
  );
  const [profilePercentVerified, setProfilePercentVerified] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [profilePictureURL, setProfilePictureURL] = useState();

  // Snackbar
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [displaySnackbar, setDisplaySnackbar] = useState(false);

  // spinner
  const [displaySpinner, setDisplaySpinner] = useState(false);

  // API URL
  const employeeProfileURL = "/api/profile";
  const profilePercentVerifiedURL = "/api/employee/profile/percentverified";
  const updateEmployeeProfileURL = "/api/profile/update";
  const getProfilePictureURL = "/api/documentrepo/document";

  const isAccountMenuOpen = Boolean(accountMenuAnchorEl);
  let isLarge = useMediaQuery(theme.breakpoints.up(size));

  const toggleNavbar = () => {
    if (isLarge) {
      if (navbar === large) {
        setNavbar(small);
      } else {
        setNavbar(large);
      }
    } else {
      if (navbar === none) {
        setNavbar(large);
      } else {
        setNavbar(none);
      }
    }
  };

  //////////////////
  //// Snackbar ////
  //////////////////
  // display snackbar
  const displayMessage = (variant, message) => {
    setSnackbarVariant(variant);
    setSnackbarMessage(message);
    setDisplaySnackbar(true);
  };
  // hide snackbar
  const handleCloseSnackbar = (event, reason) => {
    setDisplaySnackbar(false);
  };
  //////////////////////
  //// END Snackbar ////
  //////////////////////

  ///////////////////////
  //// API callbacks ////
  ///////////////////////
  const onProfileGetSuccess = (data) => {
    setEmployeeProfile(data.data);
  };
  const onProfileGetError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  const onProfileUpdateSuccess = (data) => {
    loadEmployeeProfile();
  };
  const onProfileUpdateError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  const onPictureGetSuccess = (data) => {
    setProfilePictureURL(data.url);
  };
  const onPictureGetError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
    setProfilePictureURL(null);
  };
  const onProfileUpdate = (employeeProfile) => {
    setEmployeeProfile(employeeProfile);
    updateEmployeeProfile();
  };
  const onProfilePercentVerifiedGetSuccess = (data) => {
    setProfilePercentVerified(data.data);
  };
  const onProfilePercentVerifiedGetError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  ///////////////////////////
  //// END API callbacks ////
  ///////////////////////////
  ///////////////////
  //// API calls ////
  ///////////////////
  // load Employee Profile
  const loadEmployeeProfile = () => {
    httpService.get(
      employeeProfileURL,
      onProfileGetSuccess,
      onProfileGetError,
      setDisplaySpinner
    );
  };
  // load Profile Percent Verified
  const loadProfilePercentVerified = () => {
    httpService.get(
      profilePercentVerifiedURL,
      onProfilePercentVerifiedGetSuccess,
      onProfilePercentVerifiedGetError,
      setDisplaySpinner
    );
  };
  // load profile picture
  const loadProfilePicture = () => {
    if (employeeProfile !== undefined && employeeProfile.profile_picture_id) {
      httpService.getFileURL(
        getProfilePictureURL + "/" + employeeProfile.profile_picture_id,
        onPictureGetSuccess,
        onPictureGetError,
        setDisplaySpinner
      );
    }
  };
  const updateEmployeeProfile = () => {
    httpService.put(
      updateEmployeeProfileURL,
      employeeProfile,
      onProfileUpdateSuccess,
      onProfileUpdateError,
      setDisplaySpinner
    );
  };
  ///////////////////////
  //// END API calls ////
  ///////////////////////

  const handleAccountMenuOpen = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };

  const handleSearchBarOpen = () => {};

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const accountMenuId = "account-menu";
  const renderAccountMenu = (
    <Menu
      className="OHPAppBar-OHPAccountMenu"
      anchorReference="none"
      id={accountMenuId}
      keepMounted
      open={isAccountMenuOpen}
      onClose={handleAccountMenuClose}
    >
      <List dense={true}>
        <ListItemLink href="/account">
          <ListItem button key="Account">
            <ListItemIcon>
              <PersonIcon className="OHPAccountMenuItemIcon" />
            </ListItemIcon>
            <ListItemText primary="My Account"></ListItemText>
          </ListItem>
        </ListItemLink>
        <ListItemLink href="/wallet">
          <ListItem button key="Connect a Wallet">
            <ListItemIcon>
              <AccountBalanceWalletIcon className="OHPWalletMenuItemIcon" />
            </ListItemIcon>
            <ListItemText primary="Connect a Wallet"></ListItemText>
          </ListItem>
        </ListItemLink>
        <ListItemLink href="/settings">
          <ListItem button key="Settings">
            <ListItemIcon>
              <SettingsIcon className="OHPSettingMenuItemIcon" />
            </ListItemIcon>
            <ListItemText primary="Settings"></ListItemText>
          </ListItem>
        </ListItemLink>
        <ListItemLink href="/logout">
          <ListItem button key="Logout">
            <ListItemIcon>
              <img
                className="OHPLogoutMenuItemIcon"
                src={logoutButtonIcon}
                alt="Logout"
              />
            </ListItemIcon>
            <ListItemText primary="Logout"></ListItemText>
          </ListItem>
        </ListItemLink>
      </List>
    </Menu>
  );

  const searchId = "search-bar";

  useEffect(() => {
    loadEmployeeProfile();
    loadProfilePercentVerified();
  }, []);

  useEffect(() => {
    loadProfilePicture();
  }, [employeeProfile]);

  useEffect(() => {
    if (window.innerWidth > minDrawerShowWidth) {
      const sessionNavBar = window.localStorage.getItem("navbar");
      if (sessionNavBar) {
        setNavbar(sessionNavBar);
      }
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("navbar", navbar);
  }, [navbar]);

  const renderConent = () => {
    return (
      <div>
        {content({
          ...props,
          displayMessage: displayMessage,
          setDisplaySpinner: setDisplaySpinner,
          employeeProfile: employeeProfile,
          profilePercentVerified: profilePercentVerified,
          notificationCount: notificationCount,
          profilePictureURL: profilePictureURL,
          onProfileUpdate: onProfileUpdate,
        })}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={displaySpinner ? "OHPDisplaySpinner" : "OHPHideSpinner"}>
        <CircularProgress />
      </div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, "OHPAppBar-OHPTitleContainer")}
      >
        <Toolbar>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleNavbar}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            className={clsx(classes.appBarTitleText, "OHPAppBar-OHPTitleItem")}
          >
            <Box>
              <Typography className="OHPAppBar-OHPVerifiedPercentValueText">
                {Math.round(profilePercentVerified)}%
              </Typography>
            </Box>
            <Box className="OHPAppBar-LinearProgress">
              <LinearProgress
                variant="determinate"
                color="primary"
                value={Math.round(profilePercentVerified)}
              />
            </Box>
            <Box>
              <img
                className="OHPAppBar-OHPNumberLogo"
                src={ohpNumber}
                alt="One Hunderd Percent"
              />
              {/*}
              <Typography className="OHPAppBar-OHPTitleText">100%</Typography>
              {*/}
            </Box>
          </Grid>
          <Grid item className="OHPAppBar-OHPAccountMenuItem">
            <IconButton
              className="OHPAppBar-OHPAccountButton"
              edge="end"
              aria-label="account of current user"
              aria-controls={accountMenuId}
              aria-haspopup="true"
              onClick={handleAccountMenuOpen}
              color="inherit"
            >
              <img
                className="OHPAppBar-OHPAccountButtonIcon"
                src={accountCircleButton}
                alt="App User"
              />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerLarge]: navbar === large,
          [classes.drawerSmall]: navbar === small,
          [classes.drawerNone]: navbar === none,
        })}
        classes={{
          paper: clsx({
            [classes.drawerLarge]: navbar === large,
            [classes.drawerSmall]: navbar === small,
            [classes.drawerNone]: navbar === none,
          }),
        }}
      >
        <Grid container className="OHPTopLeftContainer">
          <Grid item className="OHPLeftMenuLogoItem">
            <Box
              className={
                navbar === large
                  ? "OHPLeftMenuLogoBoxLarge"
                  : "OHPLeftMenuLogoBoxLarge"
              }
            >
              <a href="/">
                <img
                  className={
                    navbar === large
                      ? "OHPLeftMenuLogoLarge"
                      : "OHPLeftMenuLogoSmall"
                  }
                  src={navbar === large ? appLogo : ohpLogo}
                  alt="One Hundred Percent"
                />
              </a>
            </Box>
          </Grid>
        </Grid>
        <UserTile
          {...props}
          profilePictureURL={profilePictureURL}
          employeeProfile={employeeProfile}
          notificationCount={notificationCount}
        />
        <List>
          <AuthorizedMenuItem
            className={clsx(
              "OHPLeftMenuItem",
              props.location.pathname === "/jobhistory"
                ? "OHPLeftMenuItemSelected"
                : ""
            )}
            object="application"
            href={`${window.webBasedURL}/jobhistory`}
            key="Job History"
            primary="Job History"
            icon={
              <WorkOutlineIcon
                className={clsx(
                  "OHPAppBar-OHPEmploymentHistoryButtonIcon",
                  props.location.pathname === "/jobhistory"
                    ? "OHPAppBar-OHPEmploymentHistoryButtonIconSelected"
                    : ""
                )}
              />
            }
          />
          <AuthorizedMenuItem
            className="OHPLeftMenuItem"
            object="application"
            href={`${window.webBasedURL}/education`}
            key="Education"
            primary="Education"
            icon={
              <img
                className="OHPAppBar-OHPEductionButtonIcon"
                src={educationButton}
                alt="Eduction"
              />
            }
          />
          <AuthorizedMenuItem
            className="OHPLeftMenuItem"
            object="application"
            href={`${window.webBasedURL}/certifications`}
            key="Certifications"
            primary="Certifications"
            icon={
              <img
                className="OHPAppBar-OHPCertificationsButtonIcon"
                src={certificationsButton}
                alt="Certifications"
              />
            }
          />
          <AuthorizedMenuItem
            className="OHPLeftMenuItem"
            object="application"
            href={`${window.webBasedURL}/endorsements`}
            key="Endorsements"
            primary="Endorsements"
            icon={
              <img
                className="OHPAppBar-OHPEndorsementsButtonIcon"
                src={endorsementsButton}
                alt="Endorsements"
              />
            }
          />
        </List>
        {/*}
        <Grid item className="OHPAppBar-OHPSearchButtonItem">
          <IconButton
            className="OHPAppBar-OHPSearchButton"
            aria-label="search my one hundred percent"
            aria-controls={searchId}
            aria-haspopup="true"
            onClick={handleSearchBarOpen}
            color="inherit"
          >
            <img
              className="OHPAppBar-OHPSearchButtonIcon"
              src={searchButton}
              alt="App User"
            />
          </IconButton>
        </Grid>
          {*/}
        <Grid item className="OHPUpgradeButtonBox">
          <a href="/">
            <img
              className={
                navbar === large
                  ? "OHPUpgradeButtonLarge"
                  : "OHPUpgradeButtonSmall"
              }
              src={upgradeButton}
              alt="Upgrade to Premium"
            />
          </a>
        </Grid>
      </Drawer>
      {renderAccountMenu}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.pageContent}>{renderConent()}</div>
        <PageFooter />
        <SnackbarComponent
          openFlag={displaySnackbar}
          variant={snackbarVariant}
          message={snackbarMessage}
          onClose={handleCloseSnackbar}
        />
      </main>
    </div>
  );
}
