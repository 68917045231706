import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, Button, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Box from "@mui/system/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CircularProgress from "@material-ui/core/CircularProgress";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import PageFooter from "../framework/PageFooter";
import ohpNumber from "../../img/ohp_number.svg";

import {
  validateEmail,
  validateName,
  validatePassword,
} from "paf-utils-validators";
import { localDate } from "paf-utils-formatters";
import SnackbarComponent from "paf-component-snackbar";
import { logDebug } from "paf-utils-loggers";

import appLogo from "../../img/logo.svg";

import RegistrationEntity from "../../model/RegistrationEntity";

const useStyles = makeStyles((theme) => ({
  registrationAttribute: {
    width: "280px",
    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
  },
  leftBar: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  rightItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 250px)",
    },
  },
  titleText: {
    fontSize: "24px",
    [theme.breakpoints.up("md")]: {
      fontSize: "34px",
    },
  },
}));

export default function RegistrationPage(props) {
  //hide/show password state//
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  logDebug(`In RegistrationPage with props: ${JSON.stringify(props)}`);

  const classes = useStyles();

  const { httpService } = props;

  const [registration, setRegistration] = useState(new RegistrationEntity());

  // Snackbar
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [displaySnackbar, setDisplaySnackbar] = useState(false);
  // spinner
  const [displaySpinner, setDisplaySpinner] = useState(false);

  const submitRegistrationURL = "/api/employee/user/register";

  //////////////////
  //// Snackbar ////
  //////////////////
  // display snackbar
  const displayMessage = (variant, message) => {
    setSnackbarVariant(variant);
    setSnackbarMessage(message);
    setDisplaySnackbar(true);
  };
  // hide snackbar
  const handleCloseSnackbar = (event, reason) => {
    setDisplaySnackbar(false);
  };
  //////////////////////
  //// END Snackbar ////
  //////////////////////

  const validateRegistration = (registration) => {
    if (!validateName(registration.first_name, 50, 1)) {
      displayMessage(
        "error",
        "First name can be up to 50 characters long and can not be blank"
      );
      return false;
    }
    if (!validateName(registration.last_name, 50, 1)) {
      displayMessage(
        "error",
        "Last name can be up to 50 characters long and can not be blank"
      );
      return false;
    }
    if (!validateEmail(registration.email)) {
      displayMessage("error", "Please enter valid email address");
      return false;
    }
    let regex = new RegExp("^[0-9]{10}$");
    if (!regex.test((registration.mobile_phone || "").trim())) {
      displayMessage(
        "error",
        "Please enter your mobile phone number as digits only. A valid number is 10 digits long including a 3 digit area code"
      );
      return false;
    }
    if (!registration.dob) {
      displayMessage("error", "Please enter your date of birth");
      return false;
    }
    if (
      new Date(registration.dob) > new Date("2010-01-01") ||
      new Date(registration.dob) < new Date("1920-01-01")
    ) {
      displayMessage(
        "error",
        "Date of birth can not be prior to 1920 or past 2010"
      );
      return false;
    }
    regex = new RegExp("^[0-9]{4}$");
    if (!regex.test((registration.ssn || "").trim())) {
      displayMessage(
        "error",
        "Please enter the last 4 digits of your social security number"
      );
      return false;
    }
    if (!validatePassword(registration.login_password, 20, 8)) {
      displayMessage(
        "error",
        "Password should be between 8 and 20 characters and contain upper and lower case letters, digits and special characters"
      );
      return false;
    }
    if (registration.login_password !== registration.login_password_2) {
      displayMessage("error", "Two passwords don't match");
      return false;
    }
    return true;
  };

  ///////////////////////
  //// API callbacks ////
  ///////////////////////
  const onRegistrationSuccess = (data) => {
    // redirect to a page that would tell the user that registration was accepted and the user
    window.location.href = "/registration_confirmation";
  };
  const onRegistrationError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  ///////////////////////////
  //// END API callbacks ////
  ///////////////////////////
  ///////////////////
  //// API calls ////
  // save employer
  const submitRegistration = (registration) => {
    httpService.post(
      submitRegistrationURL,
      registration,
      onRegistrationSuccess,
      onRegistrationError,
      setDisplaySpinner
    );
  };
  ///////////////////////
  //// END API calls ////
  ///////////////////////
  ////////////////////////
  //// Event handlers ////
  ////////////////////////
  const handleAttributeValueChange = (name) => (event) => {
    if (!event) {
      return;
    }
    let newValue = null;
    if (event instanceof Date) {
      newValue = event.toDateString();
    } else {
      newValue = event.target.value;
    }
    setRegistration({ ...registration, [name]: newValue });
  };
  const onSubmit = () => {
    if (validateRegistration(registration)) {
      let userRegistration = JSON.parse(JSON.stringify(registration));
      userRegistration.dob = new Date(userRegistration.dob);
      userRegistration.ssn = userRegistration.ssn * 1;
      /*
      userRegistration.mobile_phone =
        "(" +
        userRegistration.mobile_phone.substring(0, 3) +
        ") " +
        userRegistration.mobile_phone.substring(3, 6) +
        "-" +
        userRegistration.mobile_phone.substring(6);
        */
      // submit
      submitRegistration(userRegistration);
    }
  };
  /////////////////////////////
  //// END: Event handlers ////
  /////////////////////////////

  return (
    <div className="RegistrationPage-Body">
      <div className={displaySpinner ? "OHPDisplaySpinner" : "OHPHideSpinner"}>
        <CircularProgress />
      </div>
      <Grid conainer className="RegistrationPage-PageContainer">
        <Grid item className="RegistrationPage-AppBarItem">
          <Box>
            <img
              className="OHPAppBar-OHPNumberLogo"
              src={ohpNumber}
              alt="One Hunderd Percent"
            />
          </Box>
        </Grid>
        <Grid item className="RegistrationPage-PageBodyItem">
          <Grid container className="RegistrationPage-PageBodyContainer">
            <Grid
              item
              className={clsx("RegistrationPage-LeftBarItem", classes.leftBar)}
            >
              <Grid item className="RegistrationPage-LogoItem">
                <img
                  className="RegistrationPage-LogoImage"
                  src={appLogo}
                  alt="One Hundred Percent"
                />
              </Grid>
            </Grid>
            <Grid
              item
              className={clsx("RegistrationPage-RightItem", classes.rightItem)}
            >
              <Grid container className="RegistrationPage-RightContainer">
                <Grid item className="RegistrationPage-RegistrationItem">
                  <Grid item className="RegistrationPage-TitleItem">
                    <Typography
                      className={clsx(
                        "RegistrationPage-TitleText",
                        classes.titleText
                      )}
                    >
                      Register Your New Account
                    </Typography>
                    <Divider className="OHPDivider" />
                  </Grid>
                  <Grid item className="RegistrationPage-RegistrationForm">
                    <Grid
                      container
                      className="RegistrationPage-RegistrationFormContainer"
                    >
                      <Grid item className="RegistrationPage-FormItem">
                        <Grid
                          container
                          className="RegistrationPage-FormItemContainer"
                        >
                          <Grid item className="RegistrationPage-FormInputItem">
                            <TextField
                              className={clsx(
                                "RegistrationPage-FormEdit",
                                "OHPTextField",
                                classes.registrationAttribute
                              )}
                              id="first_name"
                              label="First Name"
                              type="text"
                              value={registration.first_name || ""}
                              onChange={handleAttributeValueChange(
                                "first_name"
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            className="RegistrationPage-InstructionsItem"
                          >
                            <Typography className="RegistrationPage-InstructionsText">
                              Please enter your first name
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="RegistrationPage-FormItem">
                        <Grid
                          container
                          className="RegistrationPage-FormItemContainer"
                        >
                          <Grid item className="RegistrationPage-FormInputItem">
                            <TextField
                              className={clsx(
                                "RegistrationPage-FormEdit",
                                "OHPTextField",
                                classes.registrationAttribute
                              )}
                              id="last_name"
                              label="Last Name"
                              type="text"
                              value={registration.last_name || ""}
                              onChange={handleAttributeValueChange("last_name")}
                            />
                          </Grid>
                          <Grid
                            item
                            className="RegistrationPage-InstructionsItem"
                          >
                            <Typography className="RegistrationPage-InstructionsText">
                              Please enter your last name
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="RegistrationPage-FormItem">
                        <Grid
                          container
                          className="RegistrationPage-FormItemContainer"
                        >
                          <Grid item className="RegistrationPage-FormInputItem">
                            <TextField
                              className={clsx(
                                "RegistrationPage-FormEdit",
                                "OHPTextField",
                                classes.registrationAttribute
                              )}
                              id="email"
                              label="Email Address"
                              type="text"
                              value={registration.email || ""}
                              onChange={handleAttributeValueChange("email")}
                            />
                          </Grid>
                          <Grid
                            item
                            className="RegistrationPage-InstructionsItem"
                          >
                            <Typography className="RegistrationPage-InstructionsText">
                              Please enter your email address. This will be your
                              login name
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="RegistrationPage-FormItem">
                        <Grid
                          container
                          className="RegistrationPage-FormItemContainer"
                        >
                          <Grid item className="RegistrationPage-FormInputItem">
                            <TextField
                              className={clsx(
                                "RegistrationPage-FormEdit",
                                "OHPTextField",
                                classes.registrationAttribute
                              )}
                              id="mobile_phone"
                              label="Mobile Phone"
                              type="text"
                              value={registration.mobile_phone || ""}
                              onChange={handleAttributeValueChange(
                                "mobile_phone"
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            className="RegistrationPage-InstructionsItem"
                          >
                            <Typography className="RegistrationPage-InstructionsText">
                              Please enter your mobile phone number
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="RegistrationPage-FormItem">
                        <Grid
                          container
                          className="RegistrationPage-FormItemContainer"
                        >
                          <Grid item className="RegistrationPage-FormInputItem">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                className="RegistrationPage-DOBPicker"
                                label="Your Date of Birth"
                                inputFormat="MM/dd/yyyy"
                                value={localDate(registration.dob)}
                                onChange={handleAttributeValueChange("dob")}
                                renderInput={(params) => (
                                  <TextField
                                    className={clsx(
                                      "RegistrationPage-FormEdit",
                                      "OHPTextField",
                                      classes.registrationAttribute
                                    )}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid
                            item
                            className="RegistrationPage-InstructionsItem"
                          >
                            <Typography className="RegistrationPage-InstructionsText">
                              Please enter your date of birth. This information
                              will be used by the employee to locate your
                              employment records
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="RegistrationPage-FormItem">
                        <Grid
                          container
                          className="RegistrationPage-FormItemContainer"
                        >
                          <Grid item className="RegistrationPage-FormInputItem">
                            <TextField
                              className={clsx(
                                "RegistrationPage-FormEdit",
                                "OHPTextField",
                                classes.registrationAttribute
                              )}
                              id="ssn"
                              label="Last 4 Digits of SSN"
                              type="text"
                              value={registration.ssn || ""}
                              onChange={handleAttributeValueChange("ssn")}
                            />
                          </Grid>
                          <Grid
                            item
                            className="RegistrationPage-InstructionsItem"
                          >
                            <Typography className="RegistrationPage-InstructionsText">
                              Please enter last 4 digits of your Social Security
                              Number (SSN). This information will be used by the
                              employee to locate your employment records
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="RegistrationPage-FormItem">
                        <Grid
                          container
                          className="RegistrationPage-FormItemContainer"
                        >
                          <Grid item className="RegistrationPage-FormInputItem">
                            <TextField
                              className={clsx(
                                "RegistrationPage-FormEdit",
                                "OHPTextField",
                                classes.registrationAttribute
                              )}
                              id="login_password"
                              label="Login Password"
                              type={showPassword ? "text" : "password"}
                              value={registration.login_password || ""}
                              onChange={handleAttributeValueChange(
                                "login_password"
                              )}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            className="RegistrationPage-InstructionsItem"
                          >
                            <Typography className="RegistrationPage-InstructionsText">
                              Please enter your login password. Password should
                              be between 8 to 20 characters long and contain
                              upper and lower case leters, numbers and special
                              characters
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="RegistrationPage-FormItem">
                        <Grid
                          container
                          className="RegistrationPage-FormItemContainer"
                        >
                          <Grid item className="RegistrationPage-FormInputItem">
                            <TextField
                              className={clsx(
                                "RegistrationPage-FormEdit",
                                "OHPTextField",
                                classes.registrationAttribute
                              )}
                              id="login_password_2"
                              label="Re-Enter Login Password"
                              type={showPassword ? "text" : "password"}
                              value={registration.login_password_2 || ""}
                              onChange={handleAttributeValueChange(
                                "login_password_2"
                              )}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            className="RegistrationPage-InstructionsItem"
                          >
                            <Typography className="RegistrationPage-InstructionsText">
                              Please re-enter your login password
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className="RegistrationPage-FormItem">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={onSubmit}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="RegistrationPage-PageFooterItem">
                  <PageFooter />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SnackbarComponent
        openFlag={displaySnackbar}
        variant={snackbarVariant}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
}
