import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AuthenticatedRoute from "paf-component-security-authroute";
import HTTPService from "paf-service-httpservice";
import { logDebug } from "paf-utils-loggers";

// OHP application framework page
import OHPPage from "./framework/OHPPage";

// OHP framework application pages
import UserAccount from "./account/UserAccount";
import EmployeeProfile from "./profile/EmployeeProfile";
import JobHistory from "./jobhistory/JobHistory";
import Education from "./education/Education";
import Unauthorized from "./login/Unauthorized";
import Certifications from "./certifications/Certifications";
import Endorsements from "./endorsements/Endorsements";
import JobHistoryDetails from "./jobhistory/JobHistoryDetails";

// standalone application pages
import PageNotFound from "./PageNotFound";
import LoginPage from "./login/LoginPage";
import LogoutPage from "./login/LogoutPage";
import RegistrationPage from "./registration/RegistrationPage";
import RegistrationConfirmationPage from "./registration/RegistrationConfirmationPage";
import ActivationConfirmationPage from "./registration/ActivationConfirmationPage";
import AboutOHP from "./about/AboutOHP";

// config parameters
import config from "../config/config.json";
import InitiatePasswordResetPage from "./login/InitiatePasswordResetPage";
import PasswordResetPage from "./login/PasswordResetPage";
import PasswordResetConfirmationPage from "./login/PasswordResetConfirmationPage";
window.dataManagerURL = "undefined";
window.webBasedURL = "undefined";
window.logLevel = "undefined";

class Main extends Component {
  constructor(props) {
    super(props);

    window.logLevel = config.Application.LogLevel;
    logDebug("Initialized client with:");
    logDebug(`window.logLevel: ${window.logLevel}`);
    window.dataManagerURL = config.API.URL;
    logDebug(`window.dataManagerURL: ${window.dataManagerURL}`);
    window.webBasedURL = config.WEB.URL;
    logDebug(`window.webBasedURL: ${window.webBasedURL}`);

    this.state = {
      isOpen: false,
      userType: "",
      type: "",
      requestedURL: "/",
    };
    this.setRequestedURL = this.setRequestedURL.bind(this);
    this.httpService = new HTTPService(window.dataManagerURL);
  }

  setRequestedURL(url) {
    this.setState({ requestedURL: url });
  }

  render() {
    logDebug(`Current URL: ${window.location.href}`);
    return (
      <div>
        <BrowserRouter basename={window.webBasedURL}>
          <div>
            <Switch>
              <Route
                path="/about_ohp"
                render={(props) => (
                  <AboutOHP {...props} httpService={this.httpService} />
                )}
              />
              <Route
                path="/complete_registration/:token"
                render={(props) => (
                  <ActivationConfirmationPage
                    {...props}
                    httpService={this.httpService}
                  />
                )}
              />
              <Route
                path="/registration"
                render={(props) => (
                  <RegistrationPage {...props} httpService={this.httpService} />
                )}
              />
              <Route
                path="/registration_confirmation"
                render={(props) => (
                  <RegistrationConfirmationPage
                    {...props}
                    httpService={this.httpService}
                    baseURL={window.dataManagerURL}
                  />
                )}
              />
              <Route
                path="/initiate_password_reset"
                render={(props) => (
                  <InitiatePasswordResetPage
                    {...props}
                    httpService={this.httpService}
                    baseURL={window.dataManagerURL}
                  />
                )}
              />
              <Route
                path="/password_reset_confirmation"
                render={(props) => (
                  <PasswordResetConfirmationPage
                    {...props}
                    httpService={this.httpService}
                    baseURL={window.dataManagerURL}
                  />
                )}
              />
              <Route
                //add token //
                path="/password_reset/:token"
                render={(props) => (
                  <PasswordResetPage
                    {...props}
                    httpService={this.httpService}
                    baseURL={window.dataManagerURL}
                  />
                )}
              />
              <Route
                path="/login"
                render={(props) => (
                  <LoginPage
                    {...props}
                    httpService={this.httpService}
                    baseURL={window.dataManagerURL}
                    authenticateURL="/api/authenticate"
                    requestedURL={this.state.requestedURL}
                    forgotPasswordURL="/api/user/initpasswordreset"
                    passwordChangeURL="/api/user/changepassword"
                  />
                )}
              />
              <AuthenticatedRoute
                path="/logout"
                component={LogoutPage}
                redirectHandler={this.setRequestedURL}
              />
              <AuthenticatedRoute
                exact
                path="/profile"
                object="profile"
                unauthorizedURL="/unauthorized"
                unauthenticatedURL="/login"
                component={(props) => (
                  <OHPPage
                    {...props}
                    httpService={this.httpService}
                    content={function (pageProps) {
                      const componentProps = { ...props, ...pageProps };
                      return <EmployeeProfile {...componentProps} />;
                    }}
                  />
                )}
                redirectHandler={this.setRequestedURL}
              />
              <AuthenticatedRoute
                exact
                path="/jobhistorydetails/:id?"
                object="application"
                unauthorizedURL="/unauthorized"
                unauthenticatedURL="/login"
                component={(props) => (
                  <OHPPage
                    {...props}
                    httpService={this.httpService}
                    content={function (pageProps) {
                      const componentProps = { ...props, ...pageProps };
                      return (
                        <JobHistoryDetails
                          {...componentProps}
                          jobHistoryId={props.match.params.id}
                        />
                      );
                    }}
                  />
                )}
                redirectHandler={this.setRequestedURL}
              />
              <AuthenticatedRoute
                exact
                path="/account"
                object="application"
                unauthorizedURL="/unauthorized"
                unauthenticatedURL="/login"
                component={(props) => (
                  <OHPPage
                    {...props}
                    httpService={this.httpService}
                    content={function (pageProps) {
                      const componentProps = { ...props, ...pageProps };
                      return <UserAccount {...componentProps} />;
                    }}
                  />
                )}
                redirectHandler={this.setRequestedURL}
              />
              <AuthenticatedRoute
                exact
                path="/jobhistory"
                object="application"
                unauthorizedURL="/unauthorized"
                unauthenticatedURL="/login"
                component={(props) => (
                  <OHPPage
                    {...props}
                    httpService={this.httpService}
                    content={function (pageProps) {
                      const componentProps = { ...props, ...pageProps };
                      return <JobHistory {...componentProps} />;
                    }}
                  />
                )}
                redirectHandler={this.setRequestedURL}
              />
              <AuthenticatedRoute
                exact
                path="/education"
                object="application"
                unauthorizedURL="/unauthorized"
                unauthenticatedURL="/login"
                component={(props) => (
                  <OHPPage
                    {...props}
                    httpService={this.httpService}
                    content={function (pageProps) {
                      const componentProps = { ...props, ...pageProps };
                      return <Education {...componentProps} />;
                    }}
                  />
                )}
                redirectHandler={this.setRequestedURL}
              />
              <AuthenticatedRoute
                exact
                path="/certifications"
                object="application"
                unauthorizedURL="/unauthorized"
                unauthenticatedURL="/login"
                component={(props) => (
                  <OHPPage
                    {...props}
                    httpService={this.httpService}
                    content={function (pageProps) {
                      const componentProps = { ...props, ...pageProps };
                      return <Certifications {...componentProps} />;
                    }}
                  />
                )}
                redirectHandler={this.setRequestedURL}
              />
              <AuthenticatedRoute
                exact
                path="/endorsements"
                object="application"
                unauthorizedURL="/unauthorized"
                unauthenticatedURL="/login"
                component={(props) => (
                  <OHPPage
                    {...props}
                    httpService={this.httpService}
                    content={function (pageProps) {
                      const componentProps = { ...props, ...pageProps };
                      return <Endorsements {...componentProps} />;
                    }}
                  />
                )}
                redirectHandler={this.setRequestedURL}
              />
              <AuthenticatedRoute
                exact
                path="/unauthorized"
                component={(props) => (
                  <OHPPage
                    {...props}
                    httpService={this.httpService}
                    content={function (pageProps) {
                      const componentProps = { ...props, ...pageProps };
                      return <Unauthorized {...componentProps} />;
                    }}
                  />
                )}
                redirectHandler={this.setRequestedURL}
              />
              <AuthenticatedRoute
                exact
                path="/"
                object="profile"
                unauthorizedURL="/unauthorized"
                unauthenticatedURL="/login"
                component={(props) => (
                  <OHPPage
                    {...props}
                    httpService={this.httpService}
                    content={function (pageProps) {
                      const componentProps = { ...props, ...pageProps };
                      return <EmployeeProfile {...componentProps} />;
                    }}
                  />
                )}
                redirectHandler={this.setRequestedURL}
              />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default Main;
