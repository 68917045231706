import React, { useState } from "react";

import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { logDebug } from "paf-utils-loggers";
import PasswordChangeComponent from "paf-component-security-passwordchange";

export default function UserAccount(props) {
  logDebug(`In EmployeeProfile with props: ${JSON.stringify(props)}`);

  const { displayMessage } = props;

  const [showChangePassword, setShowChangePassword] = useState(false);

  const togglePasswordChange = () => {
    setShowChangePassword(!showChangePassword);
  };

  const onPasswordChange = () => {
    window.location.href = "/logout";
  };

  return (
    <div className="UserAccount-PageBody">
      <Grid container className="UserAccount-Container">
        <Grid item className="UserAccount-UserProfileItem">
          <Typography className="UserAccount-UserProfileText">
            My Account
          </Typography>
          <Divider className="OHPDivider" />
        </Grid>
        <Grid item className="UserAccount-ChangePasswordToggleItem">
          <Button
            variant="outlined"
            color="primary"
            onClick={togglePasswordChange}
          >
            Change Password
          </Button>
        </Grid>
        {showChangePassword && (
          <React.Fragment>
            <Grid item className="UserAccount-ChangePasswordItem">
              <Typography className="UserAccount-ChangePasswordText">
                Change user password
              </Typography>
            </Grid>
            <Grid item className="UserAccount-ChangePasswordComponentItem">
              <PasswordChangeComponent
                {...props}
                changePasswordURL="/api/user/changepassword"
                displayMessage={displayMessage}
                onChange={onPasswordChange}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </div>
  );
}

UserAccount.propTypes = {
  httpService: PropTypes.object.isRequired,
  displayMessage: PropTypes.func.isRequired,
  employeeProfile: PropTypes.object.isRequired,
  onProfileUpdate: PropTypes.func.isRequired,
  profilePictureURL: PropTypes.string.isRequired,
};
