import React, { useState } from "react";
import PropTypes from "prop-types";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@material-ui/core/Typography";

import { logDebug } from "paf-utils-loggers";
import UUID from "paf-utils-uuid";

import EditEmployeeProfile from "./EditEmployeeProfile";
import { Tooltip } from "@material-ui/core";

export default function EmployeeCoreProfile(props) {
  logDebug(`In EmployeeCoreProfile with props: ${JSON.stringify(props)}`);

  const { employeeProfile, notificationCount } = props;

  const [showEditProfile, setShowEditProfile] = useState(false);

  const onEditClick = () => {
    setShowEditProfile(true);
  };

  const onHideEditProfile = () => {
    setShowEditProfile(false);
  };

  const parseSkills = (skillsString) => {
    if (!skillsString) {
      return [];
    }
    return skillsString.split(",");
  };

  const onUpdate = (employeeProfile) => {
    window.location.href = "/";
  };

  return (
    <div className="EmployeeCoreProfile-Body">
      {showEditProfile && (
        <EditEmployeeProfile
          {...props}
          entityName="profile"
          entityTitle="Profile"
          itemTitle="Profile"
          isShowing={showEditProfile}
          onEntityDetailsClose={onHideEditProfile}
          entityId={employeeProfile.id}
          getItemURL="/api/profile"
          updateURL="/api/profile/update"
          onUpdate={onUpdate}
        />
      )}
      <Grid container className="EmployeeCoreProfile-Container">
        <Grid item className="EmployeeCoreProfile-ProfileTitle">
          <Grid item className="EmployeeCoreProfile-TitleContainer">
            <Grid item className="EmployeeCoreProfile-ProfileTitleItem">
              <Typography className="EmployeeCoreProfile-TitleItemText">
                My Profile
              </Typography>
              <div className="EmployeeCoreProfile-EditButtonItem">
                <Tooltip title="Edit Profile">
                  <IconButton onClick={onEditClick}>
                    <EditIcon className="EmployeeCoreProfile-EditIcon" />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
            <Divider className="OHPDivider" />
            <Grid item className="EployeeCoreProfile-TitleSummaryItem">
              <Grid
                container
                className="EmployeeCoreProfile-TitleSummaryContainer"
              >
                <Grid item className="EmployeeCoreProfile-NameAndTitleItem">
                  <Grid
                    container
                    className="EmployeeCoreProfile-NameAndTitleContainer"
                  >
                    <Grid item className="EmployeeCoreProfile-NameItem">
                      <Typography className="EmployeeCoreProfile-NameText">
                        {employeeProfile
                          ? (employeeProfile.first_name
                              ? employeeProfile.first_name
                              : "") +
                            " " +
                            (employeeProfile.last_name
                              ? employeeProfile.last_name
                              : "")
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid item className="EmployeeCoreProfile-TitleItem">
                      <Typography className="EmployeeCoreProfile-TitleText">
                        {employeeProfile ? employeeProfile.title : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="EmployeeCoreProfile-ShareProfileItem">
                  <Grid
                    container
                    className="EmployeeCoreProfile-ShareProfileContainer"
                  >
                    <Grid
                      item
                      className="EmployeeCoreProfile-ShareProfileLabelItem"
                    >
                      <Typography className="EmployeeCoreProfile-ShareProfileLabelText">
                        Share Profile
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className="EmployeeCoreProfile-ShareProfileIconItem"
                    >
                      <Tooltip title="Share Profile">
                        <IconButton>
                          <ShareIcon className="EmpoloyeeCoreProfile-ShareIcon" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          className="EmployeeCoreProfile-HorizontalDivider"
          variant="middle"
          orientation="horizontal"
          flexItem={true}
        />
        <Grid item className="EmployeeCoreProfile-Actions">
          <Grid container className="EmployeeCoreProfile-ActionsContainer">
            <Grid
              item
              className="EmpoloyeeCoreProfile-ReviewPositionButtonItem"
            >
              {notificationCount > 0 && (
                <Badge badgeContent={notificationCount} color="primary">
                  <Button
                    color="primary"
                    variant="outlined"
                    className="EmpoloyeeCoreProfile-ReviewPositionButton"
                  >
                    Review Position
                  </Button>
                </Badge>
              )}
            </Grid>
            <Grid
              item
              className="EmpoloyeeCoreProfile-ValidatePositionButtonItem"
            >
              <Button
                color="primary"
                variant="contained"
                className="EmpoloyeeCoreProfile-ValidatePositionButton"
                href="/jobhistorydetails"
              >
                Validate Position
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="EmployeeCoreProfile-Summary">
          <Typography className="EmployeeCoreProfile-SummaryText">
            {employeeProfile ? employeeProfile.summary : ""}
          </Typography>
        </Grid>
        <Grid item className="EmployeeCoreProfile-Skills">
          <Grid container className="EmployeeCoreProfile-SkillsContainer">
            <Grid item className="EmployeeCoreProfile-SkillsLabelItem">
              <Typography className="EmployeeCoreProfile-SkillsLabelText">
                Skills:
              </Typography>
            </Grid>
            <Grid item className="EmployeeCoreProfile-SkillListItem">
              <ul>
                {employeeProfile
                  ? parseSkills(employeeProfile.skills).map((skill) => {
                      return (
                        <li
                          key={UUID()}
                          className="EmployeeCoreProfile-SkillListIndividualItem"
                        >
                          <Typography className="EmployeeCoreProfile-SkillText">
                            {skill}
                          </Typography>
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

EmployeeCoreProfile.propTypes = {
  employeeProfile: PropTypes.object.isRequired,
  notificationCount: PropTypes.number.isRequired,
};
