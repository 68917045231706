import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { withStyles } from "@material-ui/core/styles";

import EntityDetailsComponent from "paf-component-entity-details";
import SnackbarComponent from "paf-component-snackbar";
import { logDebug } from "paf-utils-loggers";
import { validateName } from "paf-utils-validators";

import ProfilePictureUploader from "../account/ProfilePictureUploader";

import EmployeeProfileEntity from "../../model/EmployeeProfileEntity";

const styles = (theme) => ({
  spinnerShow: {
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
    margin: "0 auto",
    position: "absolute",
    display: "block",
  },
  spinnerHide: {
    display: "none",
  },
});

class EditEmployeeProfile extends EntityDetailsComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentEntity: new EmployeeProfileEntity(),
      originalEntity: new EmployeeProfileEntity(),
    };
    this.onProfilePictureUpdate = this.onProfilePictureUpdate.bind(this);
  }

  onProfilePictureUpdate(documentId) {
    this.props.employeeProfile.profile_picture_id = documentId;
    this.props.onProfileUpdate(this.props.employeeProfile);
    let currentEntity = this.state.currentEntity;
    currentEntity.profile_picture_id = documentId;
    this.setState({ currentEntity: currentEntity });
  }

  ///////////////////////////
  //// Entity validators ////
  ///////////////////////////
  // validate entity
  validateEntity(entity) {
    logDebug("validating entity: " + JSON.stringify(entity));
    if (!validateName(entity.first_name, 50, 1)) {
      this.displayMessage(
        "error",
        "First name can be up to 50 characters long and can not be blank"
      );
      return false;
    }
    if (!validateName(entity.last_name, 50, 1)) {
      this.displayMessage(
        "error",
        "Last name can be up to 50 characters long and can not be blank"
      );
      return false;
    }
    if (!validateName(entity.title, 50)) {
      this.displayMessage("error", "Title can be up to 50 characters long");
      return false;
    }
    if (!validateName(entity.summary, 120)) {
      this.displayMessage("error", "Summary can be up to 120 characters long");
      return false;
    }
    if (!validateName(entity.skills, 2000)) {
      this.displayMessage(
        "error",
        "Skills can be up to 2000 characters long, each skill separated by comma"
      );
      return false;
    }
    let regex = new RegExp("^[0-9]{10}$");
    if (!regex.test((entity.mobile_phone || "").trim())) {
      this.displayMessage(
        "error",
        "Please enter your mobile phone number as digits only. A valid number is 10 digits long including a 3 digit area code"
      );
      return false;
    }
    return true;
  }
  ///////////////////////////////
  //// END Entity validators ////
  ///////////////////////////////

  // OVERRIDE
  loadEntity() {
    this.httpService.get(
      this.getItemURL,
      this.onEntityGetSuccess,
      this.onEntityGetError,
      this.setDisplaySpinner
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="EditEmployeeProfile-Container">
        <Dialog
          maxWidth="lg"
          open={this.isShowing}
          onClose={this.onEntityDetailsClose}
          onEnter={this.loadEntity}
        >
          <DialogTitle>
            <Grid container className="EditEmployeeProfile-TitleContainer">
              <Grid item className="EditEmployeeProfile-CancelButtonItem">
                <Tooltip title="Close">
                  <IconButton onClick={this.onEntityDetailsClose}>
                    <CancelIcon className="EditEmployeeProfile-CancelButton" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography className="EditEmployeeProfile-MyProfileText">
                  My Profile
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className="EditEmployeeProfile-DialogConent">
            <SnackbarComponent
              openFlag={this.state.displaySnackbar}
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
              onClose={this.handleCloseSnackbar}
            />
            <div
              className={
                this.state.displaySpinner
                  ? classes.spinnerShow
                  : classes.spinnerHide
              }
            >
              <CircularProgress />
            </div>
            <Grid
              container
              className="EditEmployeeProfile-ProfileAttributesContainer"
            >
              <Grid container className="EditEmployeeProfile-PictureManageItem">
                <Grid item className="EditEmployeeProfile-PictureItem">
                  <div className="EditEmployeeProfile-PictureBox">
                    {this.props.profilePictureURL ? (
                      <img
                        className="EditEmployeeProfile-UserAvatar"
                        src={this.props.profilePictureURL}
                        alt="One Hundred Percent user"
                      />
                    ) : (
                      <AccountCircleIcon className="EditEmployeeProfile-DefaultUserAvatar" />
                    )}
                  </div>
                </Grid>
                <Grid item className="EditEmployeeProfile-PictureUploadItem">
                  <ProfilePictureUploader
                    {...this.props}
                    httpService={this.props.httpService}
                    allowUpload={true}
                    allowDelete={true}
                    uploadURL="/api/documentrepo/document/add"
                    onProfilePictureUpdate={this.onProfilePictureUpdate}
                  />
                </Grid>
              </Grid>
              <Grid item className="EditEmployeeProfile-AttributesItem">
                <Grid
                  container
                  className="EditEmployeeProfile-AttributesContainer"
                >
                  <Grid item className="EditEmployeeProfile-AttributeItem">
                    <TextField
                      className="EditEmployeeProfile-ItemEdit"
                      id="first_name"
                      label="First Name"
                      type="text"
                      value={this.state.currentEntity.first_name || ""}
                      onChange={this.handleAttributeValueChange("first_name")}
                    />
                  </Grid>
                  <Grid item className="EditEmployeeProfile-AttributeItem">
                    <TextField
                      className="EditEmployeeProfile-ItemEdit"
                      id="last_name"
                      label="Last Name"
                      type="text"
                      value={this.state.currentEntity.last_name || ""}
                      onChange={this.handleAttributeValueChange("last_name")}
                    />
                  </Grid>
                  <Grid item className="EditEmployeeProfile-AttributeItem">
                    <TextField
                      className="EditEmployeeProfile-ItemEdit"
                      id="title"
                      label="Title"
                      type="text"
                      value={this.state.currentEntity.title || ""}
                      onChange={this.handleAttributeValueChange("title")}
                    />
                  </Grid>
                  <Grid item className="EditEmployeeProfile-AttributeItem">
                    <TextField
                      className="EditEmployeeProfile-ItemEdit"
                      id="summary"
                      label="Summary"
                      multiline
                      type="text"
                      value={this.state.currentEntity.summary || ""}
                      onChange={this.handleAttributeValueChange("summary")}
                    />
                  </Grid>
                  <Grid item className="EditEmployeeProfile-AttributeItem">
                    <TextField
                      className="EditEmployeeProfile-ItemEdit"
                      id="skills"
                      label="Skills"
                      multiline
                      type="text"
                      value={this.state.currentEntity.skills || ""}
                      onChange={this.handleAttributeValueChange("skills")}
                    />
                  </Grid>
                  <Grid item className="EditEmployeeProfile-AttributeItem">
                    <TextField
                      className="EditEmployeeProfile-ItemEdit"
                      id="mobile_phone"
                      label="Mobile Phone"
                      type="text"
                      value={this.state.currentEntity.mobile_phone || ""}
                      onChange={this.handleAttributeValueChange("mobile_phone")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="EditEmployeeProfile-DialogActions">
            <Grid item className="EditEmployeeProfile-CloseButtonItem">
              <Tooltip title="Close">
                <Button
                  onClick={this.onEntityDetailsClose}
                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
              </Tooltip>
            </Grid>
            <Grid item className="EditEmployeeProfile-SaveButtonItem">
              <Tooltip title="Save Updates">
                <Button
                  onClick={this.handleSaveEntity}
                  color="primary"
                  variant="contained"
                >
                  Save
                </Button>
              </Tooltip>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditEmployeeProfile);
