import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import PropTypes from "prop-types";
import { DropzoneArea } from "material-ui-dropzone";

import SnackbarComponent from "paf-component-snackbar";
import DeleteDialogComponent from "paf-component-deletedialog";

import { logDebug } from "paf-utils-loggers";
import { validateName } from "paf-utils-validators";

// mapping of file type to icon
const fileTypes = [
  {
    fileType: "PDF",
    //    iconType: PDFIcon,
    iconFileName: "pdf.png",
    fileExtension: "pdf",
  },
  {
    fileType: "Excel",
    //    iconType: ExcelIcon,
    iconFileName: "excel.png",
    fileExtension: "xls xlsx",
  },
  {
    fileType: "Word",
    //    iconType: WordIcon,
    iconFileName: "word.png",
    fileExtension: "doc docx",
  },
  {
    fileType: "Powerpoint",
    //    iconType: PowerpointIcon,
    iconFileName: "powerpoint.png",
    fileExtension: "ppt pptx",
  },
  {
    fileType: "Pages",
    //    iconType: PagesIcon,
    iconFileName: "pages.png",
    fileExtension: "pages",
  },
  {
    fileType: "Numbers",
    //    iconType: NumbersIcon,
    iconFileName: "numbers.png",
    fileExtension: "numbers",
  },
  {
    fileType: "Keynote",
    //    iconType: KeynoteIcon,
    iconFileName: "keynote.png",
    fileExtension: "keynote",
  },
  {
    fileType: "Text",
    //    iconType: TextIcon,
    iconFileName: "text.png",
    fileExtension: "txt",
  },
  {
    fileType: "Image",
    iconFileName: "pdf.png",
    fileExtension: "jpg",
  },
  {
    fileType: "Image",
    iconFileName: "pdf.png",
    fileExtension: "jpeg",
  },
  {
    fileType: "Image",
    iconFileName: "pdf.png",
    fileExtension: "png",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  spinnerShow: {
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
    margin: "0 auto",
    position: "absolute",
    display: "block",
  },
  spinnerHide: {
    display: "none",
  },
  attachItem: {
    margin: theme.spacing(2),
    width: "100%",
  },
  attachIcon: {
    textAlign: "left",
  },
  attachment: {
    margin: theme.spacing(2, 5, 2, 0),
    alignContent: "center",
  },
  attachmentItem: {
    alignItems: "center",
  },
  fileIcon: {
    width: "60px",
  },
  fileDescription: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  cancelButton: {
    margin: theme.spacing(0, 2, 5, 0),
  },
  uploadButton: {
    margin: theme.spacing(0, 5, 5, 0),
  },
  deleteButton: {
    position: "relative",
    top: "20px",
    left: "50px",
  },
  noButton: {
    margin: theme.spacing(0, 0, 2, 0),
    color: theme.palette.grey[500],
  },
  yesButton: {
    margin: theme.spacing(0, 3, 2, 0),
  },
  dropZone: {
    backgroundColor: "#008",
  },
}));

function UploadAttachmentComponent(props) {
  const classes = useStyles();
  const { httpService, uploadURL, isShowing, onSubmit, onClose } = props;
  const [displaySpinner, setDisplaySpinner] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarVariant, setSnackbarVariant] = React.useState("info");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [closeAfterMessage, setCloseAfterMessage] = React.useState(false);
  const [values, setValues] = React.useState({
    fileDescription: "",
  });
  const [selectedFile, setSelectedFiles] = React.useState(null);

  const displayMessage = (variant, message, closeAfterMessage) => {
    setOpenSnackbar(true);
    setSnackbarVariant(variant);
    setSnackbarMessage(message);
    if (closeAfterMessage) {
      setCloseAfterMessage(closeAfterMessage);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    setOpenSnackbar(false);
    if (closeAfterMessage) {
      onClose();
    }
  };

  const validateAttributes = () => {
    if (!selectedFile) {
      displayMessage("error", "Please select file to upload");
      return false;
    }
    if (!validateName(values.fileDescription, 256)) {
      displayMessage(
        "error",
        "File description can be no more than 32 alphanumeric characters long"
      );
      return false;
    }
    return true;
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDropZoneChange = (files) => {
    logDebug("In handleDropZoneChange");
    logDebug(files);
    setSelectedFiles(files[0]);
  };

  const onUploadSuccess = (data) => {
    onSubmit(data.data.id);
    displayMessage(
      "success",
      "Profile Picture file " + data.data.file_name + " was uploaded",
      true
    );
  };

  const onUploadError = (message, data) => {
    displayMessage("error", message, true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // validate inputs
    if (!validateAttributes()) {
      return;
    }
    logDebug(selectedFile);

    let fileName = selectedFile.name;
    let fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
    logDebug(fileExt);
    let fileType = fileTypes.find(
      ({ fileExtension }) => fileExtension.indexOf(fileExt) !== -1
    ).fileType;

    logDebug(fileType);

    let formData = new FormData();
    formData.append("file_name", fileName);
    formData.append("file_type", fileType);
    formData.append("file_category", "User Profile Picture");
    formData.append("file_description", "User Profile Picture");
    formData.append("file", selectedFile);

    httpService.post(
      uploadURL,
      formData,
      onUploadSuccess,
      onUploadError,
      setDisplaySpinner
    );
  };

  return (
    <Container>
      <Dialog
        open={isShowing}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle m={5} id="form-dialog-title">
          Select picture to upload
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Container maxWidth="lg">
            <div
              className={
                displaySpinner ? classes.spinnerShow : classes.spinnerHide
              }
            >
              <CircularProgress />
            </div>
            <SnackbarComponent
              openFlag={openSnackbar}
              variant={snackbarVariant}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
            />
            <Grid container direction="column">
              <Grid item>
                <Box m={2}>
                  <DropzoneArea
                    dropzoneClass="dropZoneStyle"
                    acceptedFiles={["image/png", "image/jpeg", "image/jpg"]}
                    filesLimit={1}
                    maxFileSize={1000000}
                    showAlerts={false}
                    showFileNames={true}
                    onChange={handleDropZoneChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions padding={(10, 10, 50, 50)}>
          <Button
            className={classes.cancelButton}
            onClick={props.onClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            id="submitButton"
            className={classes.uploadButton}
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

UploadAttachmentComponent.propTypes = {};

export default function ProfilePictureUploader(props) {
  logDebug(`In ProfilePictureUploader with props: ${JSON.stringify(props)}`);

  const classes = useStyles();
  const {
    httpService,
    displayMessage,
    onProfilePictureUpdate,
    uploadURL,
    deleteURL,
    allowUpload = true,
    allowDelete = true,
  } = props;
  const [displaySpinner, setDisplaySpinner] = React.useState(false);
  const [displayUploadFile, setDisplayUploadFile] = React.useState(false);
  const [displayDeleteConfirmationDialog, setDisplayDeleteConfirmationDialog] =
    React.useState(false);
  const [clickedAttachment, setClickedAttachment] = React.useState(null);

  useEffect(() => {});

  //// Upload attachment (add attachment)
  // handle add attachment button click (open attachment dialog)
  const handleAddAttachmentClick = (event) => {
    setDisplayUploadFile(true);
  };
  // handle close attachment dialog
  const handleCloseAttachment = (event) => {
    setDisplayUploadFile(false);
  };
  // handle new attachment creation
  const handleUploadNewAttachment = (attachment) => {
    let newAttachments = props.attachments;
    newAttachments.push(attachment);
    props.setAttachments(newAttachments);
  };

  ////////////////
  //// DELETE ////
  ////////////////
  const onAttachmentDeleteSuccess = (data) => {
    displayMessage(
      "success",
      "Attachment " + clickedAttachment.fileName + " was deleted"
    );
  };
  const onAttachmentDeleteError = (message) => {
    displayMessage("error", message);
  };
  // delete attachment
  const deleteAttachment = (deleteId) => {
    httpService.delete(
      deleteURL + "/" + deleteId,
      onAttachmentDeleteSuccess,
      onAttachmentDeleteError,
      setDisplaySpinner
    );
  };
  // user clicked delete icon for an attachment
  const onDeleteClick = (attachment) => (event) => {
    setClickedAttachment(attachment);
    setDisplayDeleteConfirmationDialog(true);
  };
  // close delete dialog with YES response
  const handleCloseDeleteYes = (event) => {
    setDisplayDeleteConfirmationDialog(false);
    deleteAttachment(clickedAttachment.id);
  };
  // close delete dialog
  const handleCloseDeleteNo = (event) => {
    setDisplayDeleteConfirmationDialog(false);
  };
  // delete confirmation dialog
  const renderDeleteConfirmationDialog = (classes) => {
    return (
      <DeleteDialogComponent
        classes={classes}
        isShowing={displayDeleteConfirmationDialog}
        dialogTitle={"Delete " + clickedAttachment.fileName}
        dialogText={
          "You are about to delete " +
          clickedAttachment.fileName +
          " file attachment. Are you sure you want to do it?"
        }
        onClose={handleCloseDeleteNo}
        onDelete={handleCloseDeleteYes}
      />
    );
  };
  ////////////////////
  //// END DELETE ////
  ////////////////////

  return (
    <div>
      <UploadAttachmentComponent
        httpService={httpService}
        uploadURL={uploadURL}
        isShowing={displayUploadFile}
        onSubmit={onProfilePictureUpdate}
        onClose={handleCloseAttachment}
      />
      <Grid container direction="row">
        <Grid item className={classes.attachItem}>
          {/*}
          <Box
            className={classes.attachIcon}
            display={allowUpload ? "block" : "none"}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => handleAddAttachmentClick(event)}
            >
              Upload Picture
            </Button>
          </Box>
          {*/}
          <Tooltip title="Upload file">
            <IconButton onClick={(event) => handleAddAttachmentClick(event)}>
              <EditIcon className="ProfilePictureUploader-EditButtonIcon" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <div
          className={displaySpinner ? classes.spinnerShow : classes.spinnerHide}
        >
          <CircularProgress />
        </div>
        {displayDeleteConfirmationDialog &&
          renderDeleteConfirmationDialog(classes)}
      </Grid>
    </div>
  );
}

ProfilePictureUploader.propTypes = {
  deleteURL: PropTypes.string.isRequired,
  allowUpload: PropTypes.bool,
  allowDelete: PropTypes.bool,
};
