import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import appLogo from "../../img/logo.svg";
import { Button } from "@material-ui/core";
import PageFooter from "../framework/PageFooter";
import ArrowRight from "@mui/icons-material/ArrowRight";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem, { listItemClasses } from "@mui/material/ListItem";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { ViewColumnTwoTone } from "@mui/icons-material";
import { RowingTwoTone } from "@material-ui/icons";

export default function AboutOHP(props) {
  const firstList = [
    `Get hired faster!`,
    `Legitimatize your work history on the
    blockchain `,
    "Increases your visibility to potential employers  ",
    "Fewer obstacles during the vetting process",
    `Get verified once
    & use forever`,
    `Safely store and manage your sensitive
    information `,
  ];

  const secondList = [
    `Eliminate background checks and redundant credential
    verification​s`,
    `Eliminate resume fraud​ Reduce reliance on
    staffing agencies & headhunters​`,
    `Reduce onboarding time`,
    `Reduce overall hiring costs`,
    `Create new efficiencies with
    your HR staff`,
    `Streamline overall hiring process`,
  ];

  const useStyles = makeStyles((theme) => ({
    block: {
      flexDirection: "column",
      textAlign: "center",

      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
    },
    block1: {
      flexDirection: "column",

      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className="AboutOHP-Page">
      <Grid container className="AboutOHP-Container">
        <Grid item className="AboutOHP-PageHeaderItem">
          <Grid container className="AboutOHP-PageHeaderContainer">
            <Grid item className="AboutOHP-LogoItem">
              <img
                className="OHPLeftMenuLogoLarge"
                src={appLogo}
                alt="One Hundred Percent"
              />
            </Grid>
            <Grid item className="AboutOHP-RegisterButtonItem">
              <Button
                className="AboutOHP-RegisterButton"
                variant="contained"
                color="primary"
                href="/registration"
              >
                Register Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="AboutOHP-PageBodyItem">
          <Grid container className="AboutOHP-PageBodyContainer">
            <Grid item className="AboutOHP-TitleItem">
              <Typography className="AboutOHP-TitleText">About OHP</Typography>
              <Divider className="OHPDivider" />
            </Grid>
            <Grid item className={clsx("AboutOHP-TopContainer", classes.block)}>
              <Typography className="AboutOHP-DescriptionText1">
                One Hundred Percent is a multi-functional application that
                utilizes the immutable strength of Blockchain Technology to
                verify and ensure the integrity of our user's employment
                credentials.
              </Typography>
              <Typography className="AboutOHP-DescriptionText2">
                This provides employers with complete confidence in the data
                contained in the user profiles within the OHP platform​. OHP
                prevents individuals using our platform from fraudulently
                representing credentials and employment history. ​<br />
                <br />
                The ability of Blockchain technology ensures the truthfulness of
                the information provided on a resume which will ultimately lead
                to a substantial saving in talent acquisition costs by hiring
                entities​
              </Typography>
            </Grid>

            <Grid item className={clsx("AboutOHP-Benefits", classes.block)}>
              <Grid
                container
                className={clsx("AboutOHP-IndividualContainer", classes.block)}
              >
                <Grid item className="AboutOHP-TitleItem">
                  <Typography className="AboutOHP-TitleText">
                    Individual benefits
                  </Typography>
                  <Divider className="OHPDivider" />
                </Grid>
                <Grid item className="AboutOHP-IndividualText">
                  <List className="AboutOHP-BenefitsText">
                    {firstList.map((item) => (
                      <ListItem
                        key={item}
                        sx={{
                          paddingLeft: "0px",
                          "&:hover": {
                            background: "none",
                          },
                        }}
                      >
                        <ArrowRight
                          style={{ fontSize: "xxx-large", color: "#ab26f9" }}
                        />

                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              <Grid container className="AboutOHP-EmployerContainer">
                <Grid item className="AboutOHP-TitleItem">
                  <Typography
                    className="AboutOHP-TitleText"
                    id={"aboutEmployer"}
                  >
                    Employer benefits
                  </Typography>
                  <Divider className="OHPDivider" />
                </Grid>
                <Grid item className="AboutOHP-IndividualText">
                  <List className="AboutOHP-BenefitsText">
                    {secondList.map((item) => (
                      <ListItem
                        key={item}
                        sx={{
                          paddingLeft: "0px",
                          "&:hover": {
                            background: "none",
                          },
                        }}
                      >
                        <ArrowRight
                          style={{ fontSize: "xxx-large", color: "#ab26f9" }}
                        />

                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={clsx(
                "AboutOHP-RegisterButtonsMidContainer",
                classes.block
              )}
            >
              <Grid item className="AboutOHP-RegisterButtonItemLeft">
                <Button
                  className="AboutOHP-RegisterButton"
                  variant="contained"
                  color="primary"
                  href="/registration"
                >
                  Register as an Individual
                </Button>

                <br />

                <Link href="/login">LogIn as an individual</Link>
              </Grid>
              <Grid item className="AboutOHP-RegisterButtonItemRight">
                {/* Need to add link to employer flow */}
                <Button
                  className="AboutOHP-RegisterButton"
                  variant="contained"
                  color="primary"
                  href="https://employer.ohpgo.io/registration"
                >
                  Register as an Employer
                </Button>

                <br />

                <Link href="https://employer.ohpgo.io/login">
                  LogIn as an Employer
                </Link>
              </Grid>
            </Grid>
            <Grid item className="AboutOHP-RegisterBlockItem">
              <Grid container className="AboutOHP-RegisterBlockContainer">
                <Grid item className="AboutOHP-RegisterText">
                  <Typography className="AboutOHP-RegisterText1">
                    The security & organization of your personal employment
                    <br />
                    {"\t"}records has never been easier to manage.
                    <br />
                    {"\t"}Let's get started today.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="AboutOHP-BottomItem">
              <Grid container className="AboutOHP-BottomContainer">
                <Grid item className="AboutOHP-TitleItem">
                  <Typography className="AboutOHP-TitleText">
                    More about blockchain
                  </Typography>
                  <Divider className="OHPDivider" />
                </Grid>
                <Grid item className="AboutOHP-BottomTextItem">
                  <Grid container className="AboutOHP-BottomTextContainer">
                    <Grid
                      item
                      className={clsx("AboutOHP-BottomText", classes.block)}
                    >
                      <Typography className="AboutOHP-BottomText1">
                        Blockchain is a system of recording information in a way
                        that makes it difficult or impossible to change, hack,
                        or cheat the system.
                      </Typography>

                      <Typography
                        className={clsx("AboutOHP-BottomText2", classes.block1)}
                      >
                        A blockchain is essentially a digital ledger of
                        transactions that is duplicated and distributed across
                        the entire network of computer systems on the
                        blockchain. 
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="AboutOHP-PageFooterItem">
          <PageFooter />
        </Grid>
      </Grid>
    </div>
  );
}
