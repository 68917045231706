export default class EmployeeProfileEntity {
  id = null;
  user_id = null;
  profile_status = null;
  profile_privacy = null;
  first_name = null;
  last_name = null;
  email = null;
  mobile_phone = null;
  last_4_ssn = 0;
  dob = null;
  address_line_1 = null;
  address_line_2 = null;
  city = null;
  state = null;
  country = null;
  title = null;
  summary = null;
  skills = null;
  profile_picture_id = null;
}
