import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";

export default function Unauthorized(props) {
  return (
    <div className="Unauthorized-Page">
        <Typography className="Unauthorized-Text">
          You are not authorized to see{" "}
          {window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1
          )}
        </Typography>
    </div>
  );
}
