import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import loginLogoURL from "../../img/ohp_logo.svg";

import LoginComponent from "paf-component-security-login";

import { Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  leftBox: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      maxWidth: "400px",
      display: "flex",
    },
  },
  rightBox: {
    maxWidth: "350px",
    alignContent: "center",
    paddingRight: "28px",
    paddingLeft: "20px",
    borderRadius: "10px 10px 10px 10px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "400px",
      borderRadius: "0 15px 15px 0",
    },
  },
  rightContainer: {
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      alignItems: "start",
    },
  },
}));

export default function LoginPage(props) {
  const classes = useStyles();
  return (
    <div className="LoginPage-Body">
      <Grid item className="LoginPage-BoxesItem">
        <Grid container className="LoginPage-BoxesContainer">
          <Grid item className={clsx(classes.leftBox, "LoginPage-LeftItem")}>
            <Grid container className="LoginPage-LeftContainer">
              <Grid item className="LoginPage-WelcomeBackItem">
                <Typography className="LoginPage-WelcomeBackText">
                  Welcome Back!
                </Typography>
              </Grid>
              <Grid item className="LoginPage-SignInDescriptionItem">
                <Typography className="LoginPage-SignInDescriptionText">
                  Login to access your existing account here
                </Typography>
              </Grid>
              <Grid item className="LoginPage-LoginLogoItem">
                <Tooltip title="About OHP">
                  <a href="/about_ohp">
                    <img
                      className="LoginPage-LoginLogo"
                      src={loginLogoURL}
                      alt="About One Hundred Percent"
                    />
                  </a>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={clsx(classes.rightBox, "LoginPage-RightItem")}>
            <Grid
              container
              className={clsx(
                classes.rightContainer,
                "LoginPage-RightContainer"
              )}
            >
              <Grid item className="LoginPage-SignInTitleItem">
                <Typography className="LoginPage-SignInTitleText">
                  Sign In
                </Typography>
                <Divider className="OHPDivider" />
              </Grid>
              <Grid item className="LoginPage-SignInPromptItem">
                <Typography className="LoginPage-SignInPromptText">
                  Please login with your username and password
                </Typography>
              </Grid>
              <Grid item className="LoginPage-LoginForm">
                <LoginComponent
                  {...props}
                  userNameLabel="UserName"
                  forgotPassword={true}
                  rememberMe={true}
                  forgotPasswordURL={"/initiate_password_reset"}
                />
              </Grid>
              <Grid item className="LoginPage-CreateAccountItem">
                <Grid container className="LoginPage-CreateAccountContainer">
                  <Typography className="LoginPage-NewHereText">
                    New Here?
                  </Typography>
                  <a
                    className="LoginPage-CreateAccountLink"
                    href="/registration"
                  >
                    <Typography className="LoginPage-CreateAccountText">
                      Create an Account
                    </Typography>
                  </a>
                </Grid>
              </Grid>
              <Grid item className="LoginPage-AboutOHPItem">
                <a className="LoginPage-AboutOHPLink" href="/about_ohp">
                  <Typography className="LoginPage-AboutOHPText">
                    About OHP
                  </Typography>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

LoginPage.propTypes = {
  httpService: PropTypes.object.isRequired,
  baseURL: PropTypes.string.isRequired,
  authenticateURL: PropTypes.string.isRequired,
  requestedURL: PropTypes.string.isRequired,
};
