import React from "react";

import PropTypes from "prop-types";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ErrorIcon from "@material-ui/icons/Error";
import CheckIcon from "@material-ui/icons/Check";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CancelIcon from "@mui/icons-material/Cancel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import ohpVerifiedIcon from "../../img/ohp_verified.svg";

import { logDebug } from "paf-utils-loggers";
import { formatDate } from "paf-utils-formatters";
import { formatMoney } from "paf-utils-formatters";
import { Tooltip } from "@material-ui/core";

export default function JobHistoryRecord(props) {
  logDebug(`In JobHistoryRecord with props: ${JSON.stringify(props)}`);

  return (
    <Grid item className="JobHistoryRecord-Body">
      <Grid container className="JobHistoryRecord-Container">
        <Grid item className="JobHistoryRecord-ToggleItem">
          <IconButton
            onClick={props.onToggleView(props.record.id)}
            color="inherit"
          >
            {props.record.expanded_view ? (
              <ArrowDropDownIcon className="JobHistoryRecord-ToggleButton" />
            ) : (
              <ArrowRightIcon className="JobHistoryRecord-ToggleButton" />
            )}
          </IconButton>
        </Grid>
        <Grid item className="JobHistoryRecord-DataItem">
          <Grid container className="JobHistoryRecord-DataContainer">
            <Grid item className="JobHistoryRecord-SummaryItem">
              <Grid container className="JobHistoryRecord-SummaryContainer">
                <Grid item className="JobHistoryRecord-SummaryContentItem">
                  <Grid
                    container
                    className="JobHistoryRecord-SummaryContentContainer"
                  >
                    <Grid item className="JobHistoryRecord-SummaryRow1Item">
                      <Grid
                        container
                        className="JobHistoryRecord-SummaryRow1Container"
                      >
                        <Grid
                          item
                          className="JobHistoryRecord-EmployerNamePairItem"
                        >
                          <Grid
                            container
                            className="JobHistoryRecord-EmployerNamePairContainer"
                          >
                            <Grid
                              item
                              className="JobHistoryRecord-EmployerNameLabelItem"
                            >
                              <Typography className="JobHistoryRecord-LabelText">
                                Company Name:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              className="JobHistoryRecord-EmployerNameItem"
                            >
                              <Grid
                                container
                                className="JobHistoryRecord-EmployerNameContainer"
                              >
                                <Grid
                                  item
                                  className="JobHistoryRecord-EmployerNameVerificationItem"
                                >
                                  {props.record.employer_name_status ===
                                    "Pending" && (
                                    <Tooltip title="Pending Review">
                                      <PendingActionsIcon className="JobHistoryRecord-PendingIcon" />
                                    </Tooltip>
                                  )}
                                  {props.record.employer_name_status ===
                                    "Approved" && (
                                    <Tooltip title="Approved">
                                      <CheckIcon className="JobHistoryRecord-VerifiedIcon" />
                                    </Tooltip>
                                  )}
                                  {props.record.employer_name_status ===
                                    "Rejected" && (
                                    <Tooltip title="Rejected">
                                      <ErrorIcon className="JobHistoryRecord-VerificationFailedIcon" />
                                    </Tooltip>
                                  )}
                                  {props.record.employer_name_status ===
                                    "Disputed" && (
                                    <Tooltip title="Disputed">
                                      <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
                                    </Tooltip>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  className="JobHistoryRecord-EmployerNameValue"
                                >
                                  <Typography className="JobHistoryRecord-EmployerNameText">
                                    {props.record.employer_name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className="JobHistoryRecord-OHPVerifiedItem">
                          {props.record.status === "Approved" &&
                            props.record.verified_percent === 100 && (
                              <a href={"/jobhistorydetails/" + props.record.id}>
                                <img
                                  className="JobHistoryRecord-OneHunderedPercentVerifiedIcon"
                                  src={ohpVerifiedIcon}
                                  alt="100% Verified!"
                                />
                              </a>
                            )}
                          {props.record.status === "Rejected" && (
                            <Grid
                              container
                              className="JobHistoryRecord-RejectedContainer"
                            >
                              <Grid
                                item
                                className="JobHistoryRecord-RejectedIconItem"
                              >
                                <IconButton
                                  href={"/jobhistorydetails/" + props.record.id}
                                >
                                  <ThumbDownIcon className="JobHistoryRecord-RejectedIcon" />
                                </IconButton>
                              </Grid>
                              <Grid
                                item
                                className="JobHistoryRecord-RejectedTextItem"
                              >
                                <Typography className="JobHistoryRecord-RejectedText">
                                  Rejected
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {props.record.status === "Cancelled" && (
                            <Grid
                              container
                              className="JobHistoryRecord-CancelledContainer"
                            >
                              <Grid
                                item
                                className="JobHistoryRecord-CancelledIconItem"
                              >
                                <IconButton
                                  href={"/jobhistorydetails/" + props.record.id}
                                >
                                  <CancelIcon className="JobHistoryRecord-CancelledIcon" />
                                </IconButton>
                              </Grid>
                              <Grid
                                item
                                className="JobHistoryRecord-CancelledTextItem"
                              >
                                <Typography className="JobHistoryRecord-CancelledText">
                                  Cancelled
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {props.record.status !== "Rejected" &&
                            props.record.status !== "Approved" &&
                            props.record.status !== "Cancelled" &&
                            props.record.verified_percent < 100 && (
                              <Grid
                                container
                                className="JobHistoryRecord-NotVerifiedContainer"
                              >
                                <Grid
                                  item
                                  className="JobHistoryRecord-VerifiedPercentageItem"
                                >
                                  <Grid
                                    container
                                    className="JobHistoryRecord-VerificationPercentageContainer"
                                  >
                                    <Grid
                                      item
                                      className="JobHistoryRecord-VerificationPercentageValueItem"
                                    >
                                      <Typography className="JobHistoryRecord-VerificationPercentageValueText">
                                        {props.record.verified_percent + "%"}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      className="JobHistoryRecord-VerificationPercentageLabelItem"
                                    >
                                      <Typography className="JobHistoryRecord-VerificationPercentageLabelText">
                                        Verified
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {props.record.status === "Verifier Input" && (
                                  <Grid
                                    item
                                    className="JobHistoryRecord-ActionRequiredItem"
                                  >
                                    <Button
                                      variant="outlined"
                                      className="JobHistoryRecord-ActionRequiredButton"
                                      href={
                                        "/jobhistorydetails/" + props.record.id
                                      }
                                    >
                                      Action Required
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="JobHistoryRecord-SummaryRow2Item">
                    <Grid
                      container
                      className="JobHistoryRecord-SummaryRow2Container"
                    >
                      <Grid item className="JobHistoryRecord-PositionPairItem">
                        <Grid
                          container
                          className="JobHistoryRecord-PositionPairContainer"
                        >
                          <Grid
                            item
                            className="JobHistoryRecord-PositionLabelItem"
                          >
                            <Typography className="JobHistoryRecord-LabelText">
                              Position:
                            </Typography>
                          </Grid>
                          <Grid item className="JobHistoryRecord-PositionItem">
                            <Grid
                              container
                              className="JobHistoryRecord-PositionContainer"
                            >
                              <Grid
                                item
                                className="JobHistoryRecord-PositionVerificationItem"
                              >
                                {props.record.employment_title_status ===
                                  "Pending" && (
                                  <Tooltip title="Pending Review">
                                    <PendingActionsIcon className="JobHistoryRecord-PendingIcon" />
                                  </Tooltip>
                                )}
                                {props.record.employment_title_status ===
                                  "Approved" && (
                                  <Tooltip title="Approved">
                                    <CheckIcon className="JobHistoryRecord-VerifiedIcon" />
                                  </Tooltip>
                                )}
                                {props.record.employment_title_status ===
                                  "Rejected" && (
                                  <Tooltip title="Rejected">
                                    <ErrorIcon className="JobHistoryRecord-VerificationFailedIcon" />
                                  </Tooltip>
                                )}
                                {props.record.employment_title_status ===
                                  "Disputed" && (
                                  <Tooltip title="Disputed">
                                    <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
                                  </Tooltip>
                                )}
                              </Grid>
                              <Grid
                                item
                                className="JobHistoryRecord-PositionValue"
                              >
                                <Typography className="JobHistoryRecord-PositionText">
                                  {props.record.employment_title}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        className="JobHistoryRecord-EmploymentDatesPairItem"
                      >
                        <Grid
                          container
                          className="JobHistoryRecord-EmploymentDatesPairContainer"
                        >
                          <Grid
                            item
                            className="JobHistoryRecord-EmploymentDatesLabelItem"
                          >
                            <Typography className="JobHistoryRecord-LabelText">
                              From - To Dates:
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            className="JobHistoryRecord-EmploymentDatesItem"
                          >
                            <Grid
                              container
                              className="JobHistoryRecord-EmploymentDatesContainer"
                            >
                              <Grid
                                item
                                className="JobHistoryRecord-EmploymentDatesVerificationItem"
                              >
                                {props.record.employment_dates_status ===
                                  "Pending" && (
                                  <Tooltip title="Pending Review">
                                    <PendingActionsIcon className="JobHistoryRecord-PendingIcon" />
                                  </Tooltip>
                                )}
                                {props.record.employment_dates_status ===
                                  "Approved" && (
                                  <Tooltip title="Approved">
                                    <CheckIcon className="JobHistoryRecord-VerifiedIcon" />
                                  </Tooltip>
                                )}
                                {props.record.employment_dates_status ===
                                  "Rejected" && (
                                  <Tooltip title="Rejected">
                                    <ErrorIcon className="JobHistoryRecord-VerificationFailedIcon" />
                                  </Tooltip>
                                )}
                                {props.record.employment_dates_status ===
                                  "Disputed" && (
                                  <Tooltip title="Disputed">
                                    <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
                                  </Tooltip>
                                )}
                              </Grid>
                              <Grid
                                item
                                className="JobHistoryRecord-EmploymentDatesValue"
                              >
                                <Typography className="JobHistoryRecord-EmploymentDatesText">
                                  {formatDate(
                                    props.record.employment_start_date
                                  ) +
                                    " - " +
                                    formatDate(
                                      props.record.employment_end_date
                                    )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {props.record.expanded_view && (
              <Grid item className="JobHistoryRecord-LocationPairItem">
                <Grid
                  container
                  className="JobHistoryRecord-LocationPairContainer"
                >
                  <Grid item className="JobHistoryRecord-LocationLabelItem">
                    <Typography className="JobHistoryRecord-LabelText">
                      Location:
                    </Typography>
                  </Grid>
                  <Grid item className="JobHistoryRecord-LocationItem">
                    <Grid
                      container
                      className="JobHistoryRecord-LocationContainer"
                    >
                      <Grid
                        item
                        className="JobHistoryRecord-LocationVerificationItem"
                      >
                        {props.record.employment_location_status ===
                          "Pending" && (
                          <Tooltip title="Pending Review">
                            <PendingActionsIcon className="JobHistoryRecord-PendingIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_location_status ===
                          "Approved" && (
                          <Tooltip title="Approved">
                            <CheckIcon className="JobHistoryRecord-VerifiedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_location_status ===
                          "Rejected" && (
                          <Tooltip title="Rejected">
                            <ErrorIcon className="JobHistoryRecord-VerificationFailedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_location_status ===
                          "Disputed" && (
                          <Tooltip title="Disputed">
                            <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item className="JobHistoryRecord-LocationValue">
                        <Typography className="JobHistoryRecord-LocationText">
                          {props.record.employment_city +
                            ", " +
                            props.record.employment_state +
                            ", " +
                            props.record.employment_country}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.record.expanded_view && (
              <Grid item className="JobHistoryRecord-EmploymentTypePairItem">
                <Grid
                  container
                  className="JobHistoryRecord-EmploymentTypePairContainer"
                >
                  <Grid
                    item
                    className="JobHistoryRecord-EmploymentTypeLabelItem"
                  >
                    <Typography className="JobHistoryRecord-LabelText">
                      Employment Type:
                    </Typography>
                  </Grid>
                  <Grid item className="JobHistoryRecord-EmploymentTypeItem">
                    <Grid
                      container
                      className="JobHistoryRecord-EmploymentTypeContainer"
                    >
                      <Grid
                        item
                        className="JobHistoryRecord-EmploymentTypeVerificationItem"
                      >
                        {props.record.employment_type_status === "Pending" && (
                          <Tooltip title="Pending Review">
                            <PendingActionsIcon className="JobHistoryRecord-PendingIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_type_status === "Approved" && (
                          <Tooltip title="Approved">
                            <CheckIcon className="JobHistoryRecord-VerifiedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_type_status === "Rejected" && (
                          <Tooltip title="Rejected">
                            <ErrorIcon className="JobHistoryRecord-VerificationFailedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_type_status === "Disputed" && (
                          <Tooltip title="Disputed">
                            <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid
                        item
                        className="JobHistoryRecord-EmploymentTypeValue"
                      >
                        <Typography className="JobHistoryRecord-EmploymentTypeText">
                          {props.record.employment_type}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.record.expanded_view && (
              <Grid item className="JobHistoryRecord-IndustryPairItem">
                <Grid
                  container
                  className="JobHistoryRecord-IndustryPairContainer"
                >
                  <Grid item className="JobHistoryRecord-IndustryLabelItem">
                    <Typography className="JobHistoryRecord-LabelText">
                      Industry:
                    </Typography>
                  </Grid>
                  <Grid item className="JobHistoryRecord-IndustryItem">
                    <Grid
                      container
                      className="JobHistoryRecord-IndustryContainer"
                    >
                      <Grid
                        item
                        className="JobHistoryRecord-IndustryVerificationItem"
                      >
                        {props.record.employer_industry_status ===
                          "Pending" && (
                          <Tooltip title="Pending Review">
                            <PendingActionsIcon className="JobHistoryRecord-PendingIcon" />
                          </Tooltip>
                        )}
                        {props.record.employer_industry_status ===
                          "Approved" && (
                          <Tooltip title="Approved">
                            <CheckIcon className="JobHistoryRecord-VerifiedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employer_industry_status ===
                          "Rejected" && (
                          <Tooltip title="Rejected">
                            <ErrorIcon className="JobHistoryRecord-VerificationFailedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employer_industry_status ===
                          "Disputed" && (
                          <Tooltip title="Disputed">
                            <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item className="JobHistoryRecord-IndustryValue">
                        <Typography className="JobHistoryRecord-IndustryText">
                          {props.record.employer_industry_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.record.expanded_view && (
              <Grid item className="JobHistoryRecord-DescriptionPairItem">
                <Grid
                  container
                  className="JobHistoryRecord-DescriptionPairContainer"
                >
                  <Grid item className="JobHistoryRecord-DescriptionLabelItem">
                    <Typography className="JobHistoryRecord-LabelText">
                      Description:
                    </Typography>
                  </Grid>
                  <Grid item className="JobHistoryRecord-DescriptionItem">
                    <Grid
                      container
                      className="JobHistoryRecord-DescriptionContainer"
                    >
                      <Grid
                        item
                        className="JobHistoryRecord-DescriptionVerificationItem"
                      >
                        {props.record.employment_responsibilities_status ===
                          "Pending" && (
                          <Tooltip title="Pending Review">
                            <PendingActionsIcon className="JobHistoryRecord-PendingIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_responsibilities_status ===
                          "Approved" && (
                          <Tooltip title="Approved">
                            <CheckIcon className="JobHistoryRecord-VerifiedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_responsibilities_status ===
                          "Rejected" && (
                          <Tooltip title="Rejected">
                            <ErrorIcon className="JobHistoryRecord-VerificationFailedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_responsibilities_status ===
                          "Disputed" && (
                          <Tooltip title="Disputed">
                            <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item className="JobHistoryRecord-DescriptionValue">
                        <Typography className="JobHistoryRecord-DescriptionText">
                          {props.record.employment_responsibilities}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {props.record.expanded_view && (
              <Grid item className="JobHistoryRecord-CompensationPairItem">
                <Grid
                  container
                  className="JobHistoryRecord-CompensationPairContainer"
                >
                  <Grid item className="JobHistoryRecord-CompensationLabelItem">
                    <Typography className="JobHistoryRecord-LabelText">
                      Compensation:
                    </Typography>
                  </Grid>
                  <Grid item className="JobHistoryRecord-CompensationItem">
                    <Grid
                      container
                      className="JobHistoryRecord-CompensationContainer"
                    >
                      <Grid
                        item
                        className="JobHistoryRecord-CompensationVerificationItem"
                      >
                        {props.record.employment_compensation_status ===
                          "Pending" && (
                          <Tooltip title="Pending Review">
                            <PendingActionsIcon className="JobHistoryRecord-PendingIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_compensation_status ===
                          "Approved" && (
                          <Tooltip title="Approved">
                            <CheckIcon className="JobHistoryRecord-VerifiedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_compensation_status ===
                          "Rejected" && (
                          <Tooltip title="Rejected">
                            <ErrorIcon className="JobHistoryRecord-VerificationFailedIcon" />
                          </Tooltip>
                        )}
                        {props.record.employment_compensation_status ===
                          "Disputed" && (
                          <Tooltip title="Disputed">
                            <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item className="JobHistoryRecord-CompensationValue">
                        <Typography className="JobHistoryRecord-CompensationText">
                          {props.record.employment_compensation_type +
                            " : " +
                            formatMoney(
                              props.record.employment_compensation_rate
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item className="JobHistoryRecord-StatusItem"></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

JobHistoryRecord.propTypes = {
  record: PropTypes.object.isRequired,
};
