import React from "react";
import Button from "@material-ui/core/Button";

import LogoutComponent from "paf-component-security-logout";

import logoutLogoURL from "../../img/ohp_logo.svg";

export default function LogoutPage() {
  return (
    <div className="LogoutPage-Body">
      <LogoutComponent />
      <div className="LogoutPage-AppLogoItem">
        <img
          className="LogoutPage-LogoutLogo"
          src={logoutLogoURL}
          alt="One Hundred Percent Logo"
        />
      </div>
      <div className="LogoutPage-LoguotButtonItem">
        <Button
          className="LogoutPage-LogoutButton"
          color="primary"
          variant="outlined"
          href={`/`}
        >
          Log Back In
        </Button>
      </div>
    </div>
  );
}
