import React from "react";

import logoURL from "../img/ohp_logo.svg";
import { Typography } from "@material-ui/core";

export default function PageNotFound() {
  return (
    <div className="NotFoundPage-Body">
      <div className="NotFoundPage-AppLogoItem">
        <img
          className="NotFoundPage-LogoImage"
          src={logoURL}
          alt="One Hundred Percent Logo"
        />
      </div>
      <div className="NotFoundPage-NotFoundMessageItem">
        <Typography className="NotFoundPage-NotFoundMessageText">
          Page not found
        </Typography>
      </div>
      <div className="NotFoundPage-OHPHomeLinkItem">
          <a className="NotFoundPage-OHPHomeLinkText" href="/">OHP Home</a>
      </div>
    </div>
  );
}
