import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { globalCss } from "./index";

import Main from "./component/Main.js";

export default function App(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "light" : "light",
        },
      }),
    [prefersDarkMode]
  );
  const styles = { "@global": globalCss };
  const AppCss = withStyles(styles)(() => null);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppCss />
        <Main />
      </ThemeProvider>
    </div>
  );
}
