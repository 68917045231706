import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import ohpBackground from "../../img/background.png";

const useStyles = makeStyles((theme) => ({
  pageFooter: {
    backgroundImage: `url(${ohpBackground})`,
  },
}));

export default function PageFooter(props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.pageFooter, "PageFooter-PageFooter")}>
      <Grid item className="PageFooter-LeftTestItem">
        <Grid container className="PageFooter-LeftTextContainer">
          <Grid item className="PageFooter-TermsAndConditionItem">
            <Typography className="PageFooter-TermsAndConditionText">
              Terms And Conditions
            </Typography>
          </Grid>
          <Grid item className="PageFooter-DividerItem">
            <Divider
              className="PageFooter-Divider"
              variant="middle"
              flexItem={true}
              orientation="vertical"
            />
          </Grid>
          <Grid item className="PageFooter-PrivacyPolicyItem">
            <Typography className="PageFooter-PrivacyPolicyText">
              PRIVACY POLICY
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="PageFooter-CopyrightItem">
        <Grid container className="PageFooter-CopyrightItemContainer">
          <Grid item className="PageFooter-CopyrightItem1">
            <Typography className="PageFooter-CopyrightText">
              COPYRIGHT © 2021
            </Typography>
          </Grid>
          <Grid item className="PageFooter-CopyrightItem2">
            <Typography className="PageFooter-CopyrightText">
              ONE HUNDRED PERCENT. ALL RIGHTS RESERVED
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
