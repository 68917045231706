export default class JobHistoryEntity {
  id = null;
  status = "Created";
  verified_percent = 0;
  employer_id = null;
  employer_ohp_status = null;
  employer_x509_name = null;
  employer_name = null;
  employer_short_name = null;
  employer_other_names = null;
  employer_logo_picture_id = null;
  employer_name_status = "Pending";
  employer_name_comments = null;
  employer_ein = 0;
  employer_naics_code = 0;
  employer_industry_id = null;
  employer_industry_name = null;
  employer_industry_status = "Pending";
  employer_industry_comments = null;
  employer_primary_email = null;
  employer_primary_phone = null;
  employer_address_line_1 = null;
  employer_address_line_2 = null;
  employer_city = null;
  employer_state = null;
  employer_zip = null;
  employer_country = null;
  employee_id = null;
  employee_first_name = null;
  employee_last_name = null;
  employee_ssn = 0;
  employee_dob = null;
  employment_city = null;
  employment_state = null;
  employment_country = null;
  employment_location_status = "Pending";
  employment_location_comments = null;
  employment_title = null;
  employment_title_status = "Pending";
  employment_title_comments = null;
  employment_type = null;
  employment_type_status = "Pending";
  employment_type_comments = null;
  employment_start_date = null;
  employment_end_date = null;
  employment_dates_status = "Pending";
  employment_dates_comments = null;
  employment_responsibilities = null;
  employment_responsibilities_status = "Pending";
  employment_responsibilities_comments = null;
  employment_compensation_type = null;
  employment_compensation_rate = 0;
  employment_compensation_status = "Pending";
  employment_compensation_comments = null;
  created_by_id = null;
  created_by_role = null;
  created_by_first_name = null;
  created_by_last_name = null;
  created_date_time = null;
  last_updated_by_id = null;
  last_updated_by_role = null;
  last_updated_by_first_name = null;
  last_updated_by_last_name = null;
  last_updated_date_time = null;
}
