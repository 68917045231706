import React, { useState } from "react";

import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { logDebug } from "paf-utils-loggers";
import { IconButton, Typography } from "@material-ui/core";

export default function VerificationStatusSelector(props) {
  logDebug(
    `In VerificationStatusSelector with props: ${JSON.stringify(props)}`
  );

  const { currentValue, onValueSelect, recordStatus } = props;

  const [displayMenu, setDisplayMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event, action) => {
    setAnchorEl(null);
    const value = action === "Approve" ? "Approved" : "Rejected";
    onValueSelect(value);
  };

  return (
    <div className="VerificationStatusSelector-Container">
      {recordStatus === "Submitted" || recordStatus === "Verifier Input" ? (
        <IconButton
          className="VerificationStatusSelector-ActionButton"
          onClick={handleClick}
        >
          {currentValue === "Pending" && (
            <PendingActionsIcon className="VerificationStatusSelector-PendingIcon" />
          )}
          {currentValue === "Approved" && (
            <CheckIcon className="VerificationStatusSelector-VerifiedIcon" />
          )}
          {currentValue === "Rejected" && (
            <ErrorIcon className="VerificationStatusSelector-VerificationFailedIcon" />
          )}
          {currentValue === "Disputed" && (
            <QuestionAnswerIcon
              className="VerificationStatusSelector-DisputedIcon"
              style={{ fontSize: "16px" }}
            />
          )}
        </IconButton>
      ) : (
        <React.Fragment>
          {currentValue === "Pending" && (
            <PendingActionsIcon className="VerificationStatusSelector-PendingIcon" />
          )}
          {currentValue === "Approved" && (
            <CheckIcon className="VerificationStatusSelector-VerifiedIcon" />
          )}
          {currentValue === "Rejected" && (
            <ErrorIcon className="VerificationStatusSelector-VerificationFailedIcon" />
          )}
          {currentValue === "Disputed" && (
            <QuestionAnswerIcon className="VerificationStatusSelector-DisputedIcon" />
          )}
        </React.Fragment>
      )}
      <Menu
        className="VerificationStatusSelector-ActionMenu"
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          className="VerificationStatusSelector-MenuItem"
          key="Approve"
          onClick={(event) => handleClose(event, "Approve")}
        >
          <CheckIcon className="VerificationStatusSelector-VerifiedIcon" />
          <Typography className="VerificationStatusSelector-ActionMenuItemText">
            Approve
          </Typography>
        </MenuItem>
        <MenuItem
          className="VerificationStatusSelector-MenuItem"
          key="Reject"
          onClick={(event) => handleClose(event, "Reject")}
        >
          <ErrorIcon className="VerificationStatusSelector-VerificationFailedIcon" />
          <Typography className="VerificationStatusSelector-ActionMenuItemText">
            Reject
          </Typography>
        </MenuItem>
        {/*}
        <MenuItem
          className="VerificationStatusSelector-MenuItem"
          key="Approve"
          onClick={(event) => handleClose(event, "Approve")}
        >
          <div className="VerificationStatusSelector-Container">
            <CheckIcon className="VerificationStatusSelector-VerifiedIcon" />
          </div>
        </MenuItem>
        <MenuItem
          className="VerificationStatusSelector-MenuItem"
          key="Dispute"
          onClick={(event) => handleClose(event, "Dispute")}
        >
          <div className="VerificationStatusSelector-Container">
            <QuestionAnswerIcon className="VerificationStatusSelector-DisputedIcon" />
          </div>
        </MenuItem>
        <MenuItem
          className="VerificationStatusSelector-MenuItem"
          key="Reject"
          onClick={(event) => handleClose(event, "Reject")}
        >
          <div className="VerificationStatusSelector-Container">
            <ErrorIcon className="VerificationStatusSelector-VerificationFailedIcon" />
          </div>
        </MenuItem>
    {*/}
      </Menu>
    </div>
  );
}
