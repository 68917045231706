export default class RegistrationEntity {
  id = null;
  user_id = null;
  profile_status = null;
  first_name = null;
  last_name = null;
  email = null;
  mobile_phone = null;
  dob = null;
  ssn = null;
  login_password = null;
  login_password_2 = null;
}
