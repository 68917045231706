import React from "react";
import PropTypes from "prop-types";

import { logDebug } from "paf-utils-loggers";

export default function Certifications(props) {
  logDebug(`In EmployeeProfile with props: ${JSON.stringify(props)}`);

  return (
    <div className="OHPCertificationsBody">
      <h1>Certifications</h1>
    </div>
  );
}

Certifications.propTypes = {
  httpService: PropTypes.object.isRequired,
};
