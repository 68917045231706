import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { logDebug } from "paf-utils-loggers";

import logoURL from "../../img/ohp_logo.svg";

export default function ActivationConfirmationPage(props) {
  logDebug(
    `In ActivationConfirmationPage with props: ${JSON.stringify(props)}`
  );

  const { httpService } = props;
  const [activationMessage, setActivationMessage] = useState();
  const [userActive, setUserActive] = useState(false);

  const activateUserURL = "/api/employee/user/activate";

  ///////////////////////
  //// API callbacks ////
  ///////////////////////
  const onActivationSuccess = (data) => {
    setUserActive(true);
    setActivationMessage(
      "Congratulations! Your OHP Account has been activated. You may now log into the application with your username and password."
    );
  };
  const onActivationError = (message, data) => {
    setActivationMessage(message);
  };
  ///////////////////////////
  //// END API callbacks ////
  ///////////////////////////
  ///////////////////
  //// API calls ////
  ///////////////////
  // activate user
  const activateUser = () => {
    const url =
      activateUserURL + "/" + encodeURIComponent(props.match.params.token);
    httpService.get(url, onActivationSuccess, onActivationError);
  };
  ///////////////////////
  //// END API calls ////
  ///////////////////////

  useEffect(() => {
    activateUser();
  }, []);

  return (
    <div className="ActivationConfirmationPage-Body">
      <div className="ActivationConfirmationPage-AppLogoItem">
        <img
          className="ActivationConfirmationPage-LogoImage"
          src={logoURL}
          alt="One Hundred Percent Logo"
        />
      </div>
      <div className="ActivationConfirmationPage-ActivationResultItem">
        <Typography className="ActivationConfirmationPage-ActivationResultText">
          {activationMessage}
        </Typography>
      </div>
      {userActive && (
        <div className="ActivationConfirmationPage-ContinueButtonItem">
          <Button
            className="ActivationConfirmationPage-ContinueButton"
            color="primary"
            variant="outlined"
            href={`/`}
          >
            Login to OHP
          </Button>
        </div>
      )}
    </div>
  );
}
