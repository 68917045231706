import React from "react";

import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { logDebug } from "paf-utils-loggers";

export default function UserTile(props) {
  logDebug(`In UserTile with props: ${JSON.stringify(props)}`);

  const { employeeProfile, notificationCount, profilePictureURL } = props;

  return (
    <div className="UserTile">
      <Grid container className="UserTile-Container">
        <Grid item className="UserTile-AvatarItem">
          <Badge
            badgeContent={notificationCount}
            overlap="circular"
            color="primary"
          >
            {profilePictureURL ? (
              <img
                className="UserTile-UserAvatar"
                src={profilePictureURL}
                alt="One Hundred Percent user"
              />
            ) : (
              <AccountCircleIcon className="UserTile-DefaultUserAvatar" />
            )}
          </Badge>
        </Grid>
        <Grid item className="UserTile-UserInfoItem">
          <Grid container className="UserTile-UserInfoContainer">
            <Grid item className="UserTile-UserName">
              <Typography className="UserTile-UserNameText">
                {employeeProfile
                  ? (employeeProfile.first_name
                      ? employeeProfile.first_name
                      : "") +
                    " " +
                    (employeeProfile.last_name ? employeeProfile.last_name : "")
                  : ""}
              </Typography>
            </Grid>
            <Grid item className="UserTile-UserTitle">
              <Typography className="UserTile-UserTitleText">
                {employeeProfile
                  ? employeeProfile.title
                    ? employeeProfile.title
                    : ""
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
