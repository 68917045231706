import React, { useState } from "react";

import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import PageFooter from "../framework/PageFooter";
import ohpNumber from "../../img/ohp_number.svg";
import { validatePassword } from "paf-utils-validators";
import SnackbarComponent from "paf-component-snackbar";
import HTTPService from "paf-service-httpservice";
import { logDebug } from "paf-utils-loggers";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../img/logo.svg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Divider from "@material-ui/core/Divider";
import PasswordResetEntity from "../../model/ResetPasswordEntity";

export default function PasswordResetPage(props) {
  const [enterPassword, setEnterPassword] = useState(new PasswordResetEntity());

  //hide/show password state//
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const { httpService } = props;
  // Snackbar
  const [snackbarVariant, setSnackbarVariant] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [displaySnackbar, setDisplaySnackbar] = useState(false);

  const submitPasswordURL = "/api/user/passwordreset";

  //////////////////
  //// Snackbar ////
  //////////////////
  // display snackbar
  const displayMessage = (variant, message) => {
    setSnackbarVariant(variant);
    setSnackbarMessage(message);
    setDisplaySnackbar(true);
  };
  // hide snackbar
  const handleCloseSnackbar = (event, reason) => {
    setDisplaySnackbar(false);
  };
  //////////////////////
  //// END Snackbar ////
  //////////////////////

  const handleValueChange = (type, event) => {
    let newValue = null;
    newValue = event.target.value;
    setEnterPassword({ ...enterPassword, [type]: newValue });
  };

  const passwordValidation = (enterPassword) => {
    if (!validatePassword(enterPassword.login_password)) {
      displayMessage(
        "error",
        "Password should be between 8 and 20 characters and contain upper and lower case letters, numbers, and special characters"
      );
      return false;
    }
    if (enterPassword.login_password !== enterPassword.login_password_2) {
      displayMessage("error", "Two passwords don't match");
      return false;
    }
    return true;
  };

  //submit
  const onSubmit = () => {
    if (passwordValidation(enterPassword)) {
      submitPassword(enterPassword);
    }
  };

  ///////////////////////
  //// API callbacks ////
  ///////////////////////
  const onPasswordSuccess = (data) => {
    // redirect to the page to enter a new pasword
    window.location.href = "/login";
  };
  const onPasswordError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  ///////////////////////////
  //// END API callbacks ////
  ///////////////////////////
  ///////////////////
  //// API calls ////
  // save employer
  const submitPassword = (enterPassword) => {
    let formData = new FormData();
    formData.append("token", props.match.params.token);
    formData.append("newPassword", enterPassword.login_password);
    httpService.put(
      submitPasswordURL,
      formData,
      onPasswordSuccess,
      onPasswordError
    );
  };
  ///////////////////////
  //// END API calls ////
  ///////////////////////

  const useStyles = makeStyles((theme) => ({
    registrationAttribute: {
      width: "280px",
      [theme.breakpoints.up("md")]: {
        width: "300px",
      },
    },
    leftBar: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className="EmployeeRegistrationPage-Body">
      <Grid container className="EmployeeRegistrationPage-BodyContainer">
        <Grid item className="EmployeeRegistrationPage-AppBarItem">
          <Box>
            <img
              className="OHPAppBar-OHPNumberLogo"
              src={ohpNumber}
              alt="One Hunderd Percent"
            />
          </Box>
        </Grid>
        <Grid conainer className="EmployeePasswordReset-PageContainer">
          <Grid
            item
            className={clsx(
              "EmployeeRegistrationPage-LeftBarItem",
              classes.leftBar
            )}
          >
            <Box>
              <img
                className="EmployeeRegistrationPage-leftBarLogo"
                src={logo}
                alt="One Hundred Percent Logo"
              />
            </Box>
          </Grid>
          <Grid item className="EmployeeRegistrationPage-PageBodyItem">
            <Grid
              container
              className="EmployeeRegistrationPage-PageBodyContainer"
            >
              <Grid item className="EmployeeRegistrationPage-RightItem">
                <Grid
                  container
                  className="EmployeeRegistrationPage-RightContainer"
                >
                  <Grid item className="EmployeeRegistrationPage-Title">
                    <Typography variant="h4">New Password</Typography>
                    <Divider className="OHPDivider" />
                  </Grid>
                  <Grid
                    item
                    className="EmployeeRegistrationPage-RegistrationForm"
                  ></Grid>
                  <Grid item classname="EmployeeForgotPassword-Info">
                    <Typography variant="h7">
                      Password should be between 8 and 20 characters and contain
                      upper and lower case letters, numbers, and special
                      characters
                    </Typography>
                  </Grid>
                  <Grid item className="EmployeeForgotPassword-TextField">
                    <TextField
                      id={"NewPassword"}
                      label="New Password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type={showPassword ? "text" : "password"}
                      onChange={(event) =>
                        handleValueChange("login_password", event)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item className="EmployeeForgotPassword-TextField">
                    <TextField
                      id={"ConfirmNewPassword"}
                      label="Confrim New Password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      type={showPassword ? "text" : "password"}
                      onChange={(event) =>
                        handleValueChange("login_password_2", event)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item className="EmployeeRegistrationPage-Button">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSubmit}
                      size="Large"
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="EmployeeRegistrationPage-PageFooterItem">
                <PageFooter></PageFooter>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SnackbarComponent
          openFlag={displaySnackbar}
          variant={snackbarVariant}
          message={snackbarMessage}
          onClose={handleCloseSnackbar}
        />
      </Grid>
    </div>
  );
}
