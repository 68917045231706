import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import Box from "@mui/system/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { localDate } from "paf-utils-formatters";
import { validateName } from "paf-utils-validators";
import { logDebug } from "paf-utils-loggers";

import VerificationStatusSelector from "./VerificationStatusSelector";

import JobHistoryEntity from "../../model/JobHistoryEntity";

const countries = [
  {
    countryCode: "USA",
    countryName: "United States",
  },
];

const states = [
  {
    countryCode: "USA",
    stateCode: "AA",
    stateName: "Armed Forces America",
  },
  {
    countryCode: "USA",
    stateCode: "AE",
    stateName: "Armed Forces",
  },
  {
    countryCode: "USA",
    stateCode: "AK",
    stateName: "Alaska",
  },
  {
    countryCode: "USA",
    stateCode: "AL",
    stateName: "Alabama",
  },
  {
    countryCode: "USA",
    stateCode: "AP",
    stateName: "Armed Forces Pacific",
  },
  {
    countryCode: "USA",
    stateCode: "AR",
    stateName: "Arkansas",
  },
  {
    countryCode: "USA",
    stateCode: "AZ",
    stateName: "Arizona",
  },
  {
    countryCode: "USA",
    stateCode: "CA",
    stateName: "California",
  },
  {
    countryCode: "USA",
    stateCode: "CO",
    stateName: "Colorado",
  },
  {
    countryCode: "USA",
    stateCode: "CT",
    stateName: "Connecticut",
  },
  {
    countryCode: "USA",
    stateCode: "DC ",
    stateName: "Washington DC",
  },
  {
    countryCode: "USA",
    stateCode: "DE",
    stateName: "Delaware",
  },
  {
    countryCode: "USA",
    stateCode: "FL",
    stateName: "Florida",
  },
  {
    countryCode: "USA",
    stateCode: "GA",
    stateName: "Georgia",
  },
  {
    countryCode: "USA",
    stateCode: "GU",
    stateName: "Guam",
  },
  {
    countryCode: "USA",
    stateCode: "HI",
    stateName: "Hawaii",
  },
  {
    countryCode: "USA",
    stateCode: "IA",
    stateName: "Iowa",
  },
  {
    countryCode: "USA",
    stateCode: "ID",
    stateName: "Idaho",
  },
  {
    countryCode: "USA",
    stateCode: "IL",
    stateName: "Illinois",
  },
  {
    countryCode: "USA",
    stateCode: "IN",
    stateName: "Indiana",
  },
  {
    countryCode: "USA",
    stateCode: "KS",
    stateName: "Kansas",
  },
  {
    countryCode: "USA",
    stateCode: "KY",
    stateName: "Kentucky",
  },
  {
    countryCode: "USA",
    stateCode: "LA",
    stateName: "Louisiana",
  },
  {
    countryCode: "USA",
    stateCode: "MA",
    stateName: "Massachusetts",
  },
  {
    countryCode: "USA",
    stateCode: "MD",
    stateName: "Maryland",
  },
  {
    countryCode: "USA",
    stateCode: "ME",
    stateName: "Maine",
  },
  {
    countryCode: "USA",
    stateCode: "MI",
    stateName: "Michigan",
  },
  {
    countryCode: "USA",
    stateCode: "MN",
    stateName: "Minnesota",
  },
  {
    countryCode: "USA",
    stateCode: "MO",
    stateName: "Missouri",
  },
  {
    countryCode: "USA",
    stateCode: "MS",
    stateName: "Mississippi",
  },
  {
    countryCode: "USA",
    stateCode: "MT",
    stateName: "Montana",
  },
  {
    countryCode: "USA",
    stateCode: "NC",
    stateName: "North Carolina",
  },
  {
    countryCode: "USA",
    stateCode: "ND",
    stateName: "North Dakota",
  },
  {
    countryCode: "USA",
    stateCode: "NE",
    stateName: "Nebraska",
  },
  {
    countryCode: "USA",
    stateCode: "NH",
    stateName: "New Hampshire",
  },
  {
    countryCode: "USA",
    stateCode: "NJ",
    stateName: "New Jersey",
  },
  {
    countryCode: "USA",
    stateCode: "NM",
    stateName: "New Mexico",
  },
  {
    countryCode: "USA",
    stateCode: "NV",
    stateName: "Nevada",
  },
  {
    countryCode: "USA",
    stateCode: "NY",
    stateName: "New York",
  },
  {
    countryCode: "USA",
    stateCode: "OH",
    stateName: "Ohio",
  },
  {
    countryCode: "USA",
    stateCode: "OK",
    stateName: "Oklahoma",
  },
  {
    countryCode: "USA",
    stateCode: "OR",
    stateName: "Oregon",
  },
  {
    countryCode: "USA",
    stateCode: "PA",
    stateName: "Pennsylvania",
  },
  {
    countryCode: "USA",
    stateCode: "PR",
    stateName: "Puerto Rico",
  },
  {
    countryCode: "USA",
    stateCode: "RI",
    stateName: "Rhode Island",
  },
  {
    countryCode: "USA",
    stateCode: "SC",
    stateName: "South Carolina",
  },
  {
    countryCode: "USA",
    stateCode: "SD",
    stateName: "South Dakota",
  },
  {
    countryCode: "USA",
    stateCode: "TN",
    stateName: "Tennessee",
  },
  {
    countryCode: "USA",
    stateCode: "TX",
    stateName: "Texas",
  },
  {
    countryCode: "USA",
    stateCode: "UT",
    stateName: "Utah",
  },
  {
    countryCode: "USA",
    stateCode: "VA",
    stateName: "Virginia",
  },
  {
    countryCode: "USA",
    stateCode: "VI",
    stateName: "Virgin Islands",
  },
  {
    countryCode: "USA",
    stateCode: "VT",
    stateName: "Vermont",
  },
  {
    countryCode: "USA",
    stateCode: "WA",
    stateName: "Washington",
  },
  {
    countryCode: "USA",
    stateCode: "WI",
    stateName: "Wisconsin",
  },
  {
    countryCode: "USA",
    stateCode: "WV",
    stateName: "West Virginia",
  },
  {
    countryCode: "USA",
    stateCode: "WY",
    stateName: "Wyoming",
  },
];

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "280px",
    [theme.breakpoints.up("md")]: {
      width: "500px",
    },
  },
  dateField: {
    width: "280px",
    [theme.breakpoints.up("md")]: {
      width: "242px",
    },
  },
  actionButtonItem: {
    marginRight: "8px",
    [theme.breakpoints.up("md")]: {
      marginRight: "42px",
    },
  },
}));

export default function JobHistoryDetails(props) {
  logDebug(`In JobHistoryDetails with props: ${JSON.stringify(props)}`);

  const classes = useStyles();

  const { httpService, displayMessage, setDisplaySpinner, jobHistoryId } =
    props;
  const [jobHistory, setJobHistory] = useState(new JobHistoryEntity());
  const [industries, setIndustries] = useState([]);

  // API URL
  const getIndustriesURL = "/api/industries";
  const getJobHistoryURL = "/api/employee/jobhistory";
  const addJobHistoryURL = "/api/employee/jobhistory/add";
  const updateJobHistoryURL = "/api/employee/jobhistory/update";

  const validateJobHistory = (jobHistory) => {
    if (!jobHistory.employer_industry_id) {
      displayMessage("error", "Please select Industry before continuing");
      return false;
    }
    if (!validateName(jobHistory.employer_name, 250, 1)) {
      displayMessage(
        "error",
        "Please enter Company Name you were employed with before continuing"
      );
      return false;
    }
    if (!validateName(jobHistory.employment_title, 50, 1)) {
      displayMessage(
        "error",
        "Please enter your Job Title before continuing. Job title can be up to 50 characters long"
      );
      return false;
    }
    if (!jobHistory.employment_start_date) {
      displayMessage(
        "error",
        "Please select employment From Date before continuing"
      );
      return false;
    }
    if (!jobHistory.employment_end_date) {
      displayMessage(
        "error",
        "Please select employment End Date before continuing"
      );
      return false;
    }
    if (
      new Date(jobHistory.employment_end_date) <
      new Date(jobHistory.employment_start_date)
    ) {
      displayMessage(
        "error",
        "Employment End Date can not be prior to employment From Date"
      );
      return false;
    }
    if (!jobHistory.employment_type) {
      displayMessage(
        "error",
        "Please select Employment Type before continuing"
      );
      return false;
    }
    if (!jobHistory.employment_country) {
      displayMessage(
        "error",
        "Please select Employment Country before continuing"
      );
      return false;
    }
    if (!jobHistory.employment_state) {
      displayMessage(
        "error",
        "Please select Employment State before continuing"
      );
      return false;
    }
    if (!validateName(jobHistory.employment_city, 50, 1)) {
      displayMessage(
        "error",
        "Please enter Employment City before continuing. City can be up to 50 characters long"
      );
      return false;
    }
    if (!jobHistory.employment_compensation_type) {
      displayMessage(
        "error",
        "Please select Compensation Type before continuing"
      );
      return false;
    }
    if (jobHistory.employment_compensation_rate <= 0) {
      displayMessage(
        "error",
        "Please provide Compensation Rate before continuing"
      );
      return false;
    }
    if (!validateName(jobHistory.employment_responsibilities, 500, 1)) {
      displayMessage(
        "error",
        "Please provide your Job Responsibilities before continuing. Job Resposibilities can be up to 500 characters long"
      );
      return false;
    }
    return true;
  };

  const calculateAvailableAction = () => {
    if (!jobHistoryId) {
      return "Submit";
    }
    let action = "None";
    if (
      jobHistory.employer_name_status === "Approved" &&
      jobHistory.employer_industry_status === "Approved" &&
      jobHistory.employment_location_status === "Approved" &&
      jobHistory.employment_title_status === "Approved" &&
      jobHistory.employment_type_status === "Approved" &&
      jobHistory.employment_dates_status === "Approved" &&
      jobHistory.employment_responsibilities_status === "Approved" &&
      jobHistory.employment_compensation_status === "Approved" &&
      jobHistory.status === "Verifier Input"
    ) {
      action = "Approve";
    }
    if (
      (jobHistory.employer_name_status === "Rejected" ||
        jobHistory.employer_industry_status === "Rejected" ||
        jobHistory.employment_location_status === "Rejected" ||
        jobHistory.employment_title_status === "Rejected" ||
        jobHistory.employment_type_status === "Rejected" ||
        jobHistory.employment_dates_status === "Rejected" ||
        jobHistory.employment_responsibilities_status === "Rejected" ||
        jobHistory.employment_compensation_status === "Rejected") &&
      jobHistory.status === "Verifier Input"
    ) {
      action = "Reject";
    }
    return action;
  };

  ////////////////////////
  //// Event handlers ////
  ////////////////////////
  const handleActionSelection = (name) => (value) => {
    let currentJobHistory = JSON.parse(JSON.stringify(jobHistory));
    if (value === "Approve") {
    }
    if (value === "Reject") {
    }
    currentJobHistory[name] = value;
    setJobHistory(currentJobHistory);
  };
  const handleAttributeValueChange = (name) => (event) => {
    if (!event) {
      return;
    }
    let newValue = null;
    if (event instanceof Date) {
      newValue = event.toDateString();
    } else {
      newValue = event.target.value;
    }
    setJobHistory({ ...jobHistory, [name]: newValue });
  };
  const onSubmit = () => {
    // validate user input
    if (validateJobHistory(jobHistory)) {
      // add employee related attributes from a profile
      jobHistory.status = "Submitted";
      jobHistory.employee_id = props.employeeProfile.user_id;
      jobHistory.employer_ohp_status = "None";
      jobHistory.employer_x509_name = null;
      jobHistory.employee_first_name = props.employeeProfile.first_name;
      jobHistory.employee_last_name = props.employeeProfile.last_name;
      jobHistory.employee_ssn = props.employeeProfile.ssn;
      jobHistory.employee_dob = props.employeeProfile.dob;
      jobHistory.employment_compensation_rate = parseInt(
        jobHistory.employment_compensation_rate
      );
      jobHistory.employment_start_date = new Date(
        jobHistory.employment_start_date
      );
      jobHistory.employment_end_date = new Date(jobHistory.employment_end_date);
      jobHistory.employer_short_name = jobHistory.employer_name;
      jobHistory.employer_industry_name = industries.find(
        (industry) => industry.id === jobHistory.employer_industry_id
      ).industry;
      jobHistory.created_by_id = props.employeeProfile.user_id;
      jobHistory.created_by_role = "Employee";
      jobHistory.created_by_first_name = props.employeeProfile.first_name;
      jobHistory.created_by_last_name = props.employeeProfile.last_name;
      jobHistory.created_date_time = new Date();
      jobHistory.last_updated_by_id = props.employeeProfile.user_id;
      jobHistory.last_updated_by_role = "Employee";
      jobHistory.last_updated_by_first_name = props.employeeProfile.first_name;
      jobHistory.last_updated_by_last_name = props.employeeProfile.last_name;
      jobHistory.last_updated_date_time = new Date();
      addJobHistory(jobHistory);
    }
  };
  const onApprove = () => {
    // validate user input
    if (validateJobHistory(jobHistory)) {
      jobHistory.status = "Approved";
      jobHistory.last_updated_by_id = props.employeeProfile.user_id;
      jobHistory.last_updated_by_role = "Employee";
      jobHistory.last_updated_by_first_name = props.employeeProfile.first_name;
      jobHistory.last_updated_by_last_name = props.employeeProfile.last_name;
      jobHistory.last_updated_date_time = new Date();
      updateJobHistory(jobHistory);
    }
  };
  const onReject = () => {
    // validate user input
    if (validateJobHistory(jobHistory)) {
      jobHistory.status = "Cancelled";
      jobHistory.last_updated_by_id = props.employeeProfile.user_id;
      jobHistory.last_updated_by_role = "Employee";
      jobHistory.last_updated_by_first_name = props.employeeProfile.first_name;
      jobHistory.last_updated_by_last_name = props.employeeProfile.last_name;
      jobHistory.last_updated_date_time = new Date();
      updateJobHistory(jobHistory);
    }
  };
  /////////////////////////////
  //// END: Event handlers ////
  /////////////////////////////

  ///////////////////////
  //// API callbacks ////
  ///////////////////////
  const onJobHistoryAddSuccess = (data) => {
    displayMessage(
      "success",
      "New employment record was submitted for validation"
    );
    window.location.href = "/";
  };
  const onJobHistoryAddError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  const onJobHistoryUpdateSuccess = (data) => {
    if (data.data.status === "Approved") {
      displayMessage(
        "success",
        "Congratulations! Employment record is 100% Approved!"
      );
    }
    if (data.data.status === "Rejected") {
      displayMessage(
        "info",
        "Employment record was Rejected. It will not be included on your profile"
      );
    }
    window.location.href = "/jobhistory";
  };
  const onJobHistoryUpdateError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  const onIndustriesGetSuccess = (data) => {
    setIndustries(data.data);
  };
  const onIndustriesGetError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  const onJobHistoryGetSuccess = (data) => {
    setJobHistory(data.data);
  };
  const onJobHistoryGetError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  ///////////////////////////
  //// END API callbacks ////
  ///////////////////////////
  ///////////////////
  //// API calls ////
  ///////////////////
  // get job history
  const getJobHistory = () => {
    httpService.get(
      getJobHistoryURL + "/" + jobHistoryId,
      onJobHistoryGetSuccess,
      onJobHistoryGetError,
      setDisplaySpinner
    );
  };
  // add job history record
  const addJobHistory = (job) => {
    httpService.post(
      addJobHistoryURL,
      job,
      onJobHistoryAddSuccess,
      onJobHistoryAddError,
      setDisplaySpinner
    );
  };
  // update job history record
  const updateJobHistory = (job) => {
    httpService.put(
      updateJobHistoryURL,
      job,
      onJobHistoryUpdateSuccess,
      onJobHistoryUpdateError,
      setDisplaySpinner
    );
  };
  // get Industries
  const getIndustries = () => {
    httpService.get(
      getIndustriesURL,
      onIndustriesGetSuccess,
      onIndustriesGetError,
      setDisplaySpinner
    );
  };
  ///////////////////////
  //// END API calls ////
  ///////////////////////

  useEffect(() => {
    getIndustries();
    if (jobHistoryId) {
      getJobHistory();
    }
  }, []);

  return (
    <div className="JobHistoryDetails-PageBody">
      <Grid container className="JobHistoryDetails-Container">
        <Grid item className="JobHistoryDetails-DataItem">
          <Grid container className="JobHistoryDetails-DataContainer">
            <Grid item className="JobHistoryDetails-TitleItem">
              <Typography className="JobHistoryDetails-TitleText">
                {jobHistoryId ? "Job History Details" : "Validate New Position"}
              </Typography>
              <Divider className="OHPDivider" />
            </Grid>
            <Grid item className="JobHistoryDetails-IntroItem">
              <Typography className="JobHistoryDetails-IntroText">
                {jobHistoryId
                  ? "Please review feedback from Verifier and take appropriate action"
                  : "Please provide details of the position you want to validate with OHP. It is important to provide complete and correct information for all fields below in order to expedite approval of your position"}
              </Typography>
            </Grid>
            {jobHistoryId && (
              <Grid
                container
                className="JobHistoryDetails-ColumnItemsContainer"
              >
                <Typography className="JobHistoryDetails-AttributeLabelText">
                  Request Status:
                </Typography>
                <Typography
                  className={clsx(
                    "JobHistoryDetails-AttributeValueText",
                    jobHistory.status === "Submitted"
                      ? "JobHistoryDetails-SubmittedStatusText"
                      : jobHistory.status === "Submitter Input" ||
                        jobHistory.status === "Verifier Input"
                      ? "JobHistoryDetails-DisputedStatusText"
                      : jobHistory.status === "Approved"
                      ? "JobHistoryDetails-ApprovedStatusText"
                      : jobHistory.status === "Rejected"
                      ? "JobHistoryDetails-RejectedStatusText"
                      : jobHistory.status === "Cancelled"
                      ? "JobHistoryDetails-RejectedStatusText"
                      : ""
                  )}
                >
                  {jobHistory.status}
                </Typography>
              </Grid>
            )}
            <Grid item className="JobHistoryDetails-IndustryItem">
              <Grid container className="JobHistoryDetails-IndustryContainer">
                <Grid item className="JobHistoryDetails-IndustrySelectItem">
                  <FormControl
                    className={clsx(
                      "JobHistoryDetails-IndustryForm",
                      "OHPTextField",
                      classes.textField
                    )}
                  >
                    <InputLabel id="validate-position-industry-label">
                      Industry
                    </InputLabel>
                    <Select
                      labelId="validate-position-industry-label"
                      disabled={jobHistoryId}
                      id="employer_industry_id"
                      value={jobHistory.employer_industry_id || ""}
                      label="Industry"
                      onChange={handleAttributeValueChange(
                        "employer_industry_id"
                      )}
                    >
                      {industries.map((industry) => {
                        return (
                          <MenuItem key={industry.id} value={industry.id}>
                            {industry.industry}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {jobHistoryId ? (
                  <Grid
                    className={clsx(
                      "EmployeeRequestDetails-ActionsItem",
                      classes.actionButtonItem
                    )}
                  >
                    <VerificationStatusSelector
                      currentValue={jobHistory.employer_industry_status}
                      onValueSelect={handleActionSelection(
                        "employer_industry_status"
                      )}
                      recordStatus={jobHistory.status}
                    />
                  </Grid>
                ) : (
                  <Grid item className="JobHistoryDetails-InstructionsItem">
                    <Typography className="JobHistoryDetails-InstructionsText">
                      Select your employer's industry
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {(jobHistory.employer_industry_status === "Disputed" ||
              jobHistory.employer_industry_status === "Rejected") && (
              <Grid item>
                <Box className="JobHistoryDetails-CommentsBox">
                  <TextField
                    className={clsx(
                      "JobHistoryDetails-CommentsEdit",
                      "OHPTextField",
                      classes.textField
                    )}
                    disabled={
                      jobHistory.status !== "Submitted" &&
                      jobHistory.status !== "Submitter Input"
                    }
                    id="employer_industry_comments"
                    label="Industry Comments"
                    multiline
                    rows={4}
                    type="text"
                    value={jobHistory.employer_industry_comments || ""}
                    onChange={handleAttributeValueChange(
                      "employer_industry_comments"
                    )}
                  />
                </Box>
              </Grid>
            )}
            <Grid item className="JobHistoryDetails-CompanyNameItem">
              <Grid
                container
                className="JobHistoryDetails-CompanyNameContainer"
              >
                <Grid item className="JobHistoryDetails-CompanyName">
                  <Box className="JobHistoryDetails-CompanyNameBox">
                    <TextField
                      className={clsx(
                        "JobHistoryDetails-CompanyNameEdit",
                        "OHPTextField",
                        classes.textField
                      )}
                      disabled={jobHistoryId}
                      id="employer_name"
                      label="Company Name"
                      type="text"
                      value={jobHistory.employer_name || ""}
                      onChange={handleAttributeValueChange("employer_name")}
                    />
                  </Box>
                </Grid>
                {jobHistoryId ? (
                  <Grid
                    className={clsx(
                      "EmployeeRequestDetails-ActionsItem",
                      classes.actionButtonItem
                    )}
                  >
                    <VerificationStatusSelector
                      currentValue={jobHistory.employer_name_status}
                      onValueSelect={handleActionSelection(
                        "employer_name_status"
                      )}
                      recordStatus={jobHistory.status}
                    />
                  </Grid>
                ) : (
                  <Grid item className="JobHistoryDetails-InstructionsItem">
                    <Typography className="JobHistoryDetails-InstructionsText">
                      Enter company name that you were employed with.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {(jobHistory.employer_name_status === "Disputed" ||
              jobHistory.employer_name_status === "Rejected") && (
              <Grid item>
                <Box className="JobHistoryDetails-CommentsBox">
                  <TextField
                    className={clsx(
                      "JobHistoryDetails-CommentsEdit",
                      "OHPTextField",
                      classes.textField
                    )}
                    disabled={
                      jobHistory.status !== "Submitted" &&
                      jobHistory.status !== "Submitter Input"
                    }
                    id="employer_name_comments"
                    label="Company Name Comments"
                    multiline
                    rows={4}
                    type="text"
                    value={jobHistory.employer_name_comments || ""}
                    onChange={handleAttributeValueChange(
                      "employer_name_comments"
                    )}
                  />
                </Box>
              </Grid>
            )}
            <Grid item className="JobHistoryDetails-JobTitleItem">
              <Grid container className="JobHistoryDetails-JobTitleContainer">
                <Grid item className="JobHistoryDetails-JobTitleEditItem">
                  <Box className="JobHistoryDetails-JobTitleBox">
                    <TextField
                      className={clsx(
                        "JobHistoryDetails-JobTitleEdit",
                        "OHPTextField",
                        classes.textField
                      )}
                      id="employment_title"
                      disabled={jobHistoryId}
                      label="Job Title"
                      type="text"
                      value={jobHistory.employment_title || ""}
                      onChange={handleAttributeValueChange("employment_title")}
                    />
                  </Box>
                </Grid>
                {jobHistoryId ? (
                  <Grid
                    className={clsx(
                      "EmployeeRequestDetails-ActionsItem",
                      classes.actionButtonItem
                    )}
                  >
                    <VerificationStatusSelector
                      currentValue={jobHistory.employment_title_status}
                      onValueSelect={handleActionSelection(
                        "employment_title_status"
                      )}
                      recordStatus={jobHistory.status}
                    />
                  </Grid>
                ) : (
                  <Grid item className="JobHistoryDetails-InstructionsItem">
                    <Typography className="JobHistoryDetails-InstructionsText">
                      Enter your title as you were leaving the company.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {(jobHistory.employment_title_status === "Disputed" ||
              jobHistory.employment_title_status === "Rejected") && (
              <Grid item>
                <Box className="JobHistoryDetails-CommentsBox">
                  <TextField
                    className={clsx(
                      "JobHistoryDetails-CommentsEdit",
                      "OHPTextField",
                      classes.textField
                    )}
                    disabled={
                      jobHistory.status !== "Submitted" &&
                      jobHistory.status !== "Submitter Input"
                    }
                    id="employment_title_comments"
                    label="Job Title Comments"
                    multiline
                    rows={4}
                    type="text"
                    value={jobHistory.employment_title_comments || ""}
                    onChange={handleAttributeValueChange(
                      "employment_title_comments"
                    )}
                  />
                </Box>
              </Grid>
            )}
            <Grid item className="JobHistoryDetails-EmploymentDatesItem">
              <Grid
                container
                className="JobHistoryDetails-EmploymentDatesContainer"
              >
                <Grid item className="JobHistoryDetails-FromDateItem">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      className="JobHistoryDetails-FromDatePicker"
                      disabled={jobHistoryId}
                      label="From Date"
                      inputFormat="MM/dd/yyyy"
                      value={localDate(jobHistory.employment_start_date)}
                      onChange={handleAttributeValueChange(
                        "employment_start_date"
                      )}
                      renderInput={(params) => (
                        <TextField
                          className={clsx(
                            "JobHistoryDetails-FromDateEdit",
                            "OHPTextField",
                            classes.dateField
                          )}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item className="JobHistoryDetails-ToDateItem">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      className="JobHistoryDetails-ToDatePicker"
                      disabled={jobHistoryId}
                      label="To Date"
                      inputFormat="MM/dd/yyyy"
                      value={localDate(jobHistory.employment_end_date)}
                      onChange={handleAttributeValueChange(
                        "employment_end_date"
                      )}
                      renderInput={(params) => (
                        <TextField
                          className={clsx(
                            "JobHistoryDetails-ToDateEdit",
                            "OHPTextField",
                            classes.dateField
                          )}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {jobHistoryId ? (
                  <Grid
                    className={clsx(
                      "EmployeeRequestDetails-ActionsItem",
                      classes.actionButtonItem
                    )}
                  >
                    <VerificationStatusSelector
                      currentValue={jobHistory.employment_dates_status}
                      onValueSelect={handleActionSelection(
                        "employment_dates_status"
                      )}
                      recordStatus={jobHistory.status}
                    />
                  </Grid>
                ) : (
                  <Grid item className="JobHistoryDetails-InstructionsItem">
                    <Typography className="JobHistoryDetails-InstructionsText">
                      Enter the dates you worked at your company. From and To.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {(jobHistory.employment_dates_status === "Disputed" ||
              jobHistory.employment_dates_status === "Rejected") && (
              <Grid item>
                <Box className="JobHistoryDetails-CommentsBox">
                  <TextField
                    className={clsx(
                      "JobHistoryDetails-CommentsEdit",
                      "OHPTextField",
                      classes.textField
                    )}
                    disabled={
                      jobHistory.status !== "Submitted" &&
                      jobHistory.status !== "Submitter Input"
                    }
                    id="employment_dates_comments"
                    label="Employment Dates Comments"
                    multiline
                    rows={4}
                    type="text"
                    value={jobHistory.employment_dates_comments || ""}
                    onChange={handleAttributeValueChange(
                      "employment_dates_comments"
                    )}
                  />
                </Box>
              </Grid>
            )}
            <Grid item className="JobHistoryDetails-EmploymentTypeItem">
              <Grid
                container
                className="JobHistoryDetails-EmploymentTypeContainer"
              >
                <Grid
                  item
                  className="JobHistoryDetails-EmploymentTypeSelectItem"
                >
                  <FormControl
                    className={clsx(
                      "JobHistoryDetails-EmploymentTypeForm",
                      "OHPTextField",
                      classes.textField
                    )}
                  >
                    <InputLabel id="validate-position-employment-type-label">
                      Employment Type
                    </InputLabel>
                    <Select
                      labelId="validate-position-employment-type-label"
                      id="employment_type"
                      disabled={jobHistoryId}
                      value={jobHistory.employment_type || ""}
                      label="Employment Type"
                      onChange={handleAttributeValueChange("employment_type")}
                    >
                      <MenuItem key="Full-Time" value="Full-Time">
                        Full-Time
                      </MenuItem>
                      <MenuItem key="Part-Time" value="Part-Time">
                        Part-Time
                      </MenuItem>
                      <MenuItem key="Contract" value="Contract">
                        Contract
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {jobHistoryId ? (
                  <Grid
                    className={clsx(
                      "EmployeeRequestDetails-ActionsItem",
                      classes.actionButtonItem
                    )}
                  >
                    <VerificationStatusSelector
                      currentValue={jobHistory.employment_type_status}
                      onValueSelect={handleActionSelection(
                        "employment_type_status"
                      )}
                      recordStatus={jobHistory.status}
                    />
                  </Grid>
                ) : (
                  <Grid item className="JobHistoryDetails-InstructionsItem">
                    <Typography className="JobHistoryDetails-InstructionsText">
                      Select your employment type.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {(jobHistory.employment_type_status === "Disputed" ||
              jobHistory.employment_type_status === "Rejected") && (
              <Grid item>
                <Box className="JobHistoryDetails-CommentsBox">
                  <TextField
                    className={clsx(
                      "JobHistoryDetails-CommentsEdit",
                      "OHPTextField",
                      classes.textField
                    )}
                    disabled={
                      jobHistory.status !== "Submitted" &&
                      jobHistory.status !== "Submitter Input"
                    }
                    id="employment_type_comments"
                    label="Employment Type Comments"
                    multiline
                    rows={4}
                    type="text"
                    value={jobHistory.employment_type_comments || ""}
                    onChange={handleAttributeValueChange(
                      "employment_type_comments"
                    )}
                  />
                </Box>
              </Grid>
            )}
            <Divider
              className="JobHistoryDetails-HorizontalDivider"
              variant="middle"
              orientation="horizontal"
              flexItem={true}
            />
            <Grid item className="JobHistoryDetails-EmploymentLocationItem">
              <Grid container className="JobHistoryDetails-AttributeContainer">
                <Grid item className="JobHistoryDetails-LocationItemsItem">
                  <Grid item className="JobHistoryDetails-MultiItemsContainer">
                    <Grid
                      item
                      className="JobHistoryDetails-EmploymentCountryItem"
                    >
                      <Grid
                        container
                        className="JobHistoryDetails-EmploymentCountryContainer"
                      >
                        <Grid
                          item
                          className="JobHistoryDetails-EmploymentCountrySelectItem"
                        >
                          <FormControl
                            className={clsx(
                              "JobHistoryDetails-EmploymentCountryForm",
                              "OHPTextField",
                              classes.textField
                            )}
                          >
                            <InputLabel id="validate-position-employment-country-label">
                              Employment Country
                            </InputLabel>
                            <Select
                              labelId="validate-position-employment-country-label"
                              id="employment_Country"
                              disabled={jobHistoryId}
                              value={jobHistory.employment_country || ""}
                              label="Employment Country"
                              onChange={handleAttributeValueChange(
                                "employment_country"
                              )}
                            >
                              {countries.map((country) => {
                                return (
                                  <MenuItem
                                    key={country.countryCode}
                                    value={country.countryCode}
                                  >
                                    {country.countryName}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        {!jobHistoryId && (
                          <Grid
                            item
                            className="JobHistoryDetails-InstructionsItem"
                          >
                            <Typography className="JobHistoryDetails-InstructionsText">
                              Country where your employment office was located
                              (not its headquarters)
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      className="JobHistoryDetails-EmploymentStateItem"
                    >
                      <Grid
                        container
                        className="JobHistoryDetails-EmploymentStateContainer"
                      >
                        <Grid
                          item
                          className="JobHistoryDetails-EmploymentStateSelectItem"
                        >
                          <FormControl
                            className={clsx(
                              "JobHistoryDetails-EmploymentStateForm",
                              "OHPTextField",
                              classes.textField
                            )}
                          >
                            <InputLabel id="validate-position-employment-state-label">
                              Employment State
                            </InputLabel>
                            <Select
                              labelId="validate-position-employment-state-label"
                              id="employment_state"
                              disabled={jobHistoryId}
                              value={jobHistory.employment_state || ""}
                              label="Employment State"
                              onChange={handleAttributeValueChange(
                                "employment_state"
                              )}
                            >
                              {states.map((state) => {
                                return (
                                  <MenuItem
                                    key={state.stateCode}
                                    value={state.stateCode}
                                  >
                                    {state.stateName}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        {!jobHistoryId && (
                          <Grid
                            item
                            className="JobHistoryDetails-InstructionsItem"
                          >
                            <Typography className="JobHistoryDetails-InstructionsText">
                              State where your employment office was located
                              (not its headquarters)
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item className="JobHistoryDetails-EmploymentCityItem">
                      <Grid
                        container
                        className="JobHistoryDetails-EmploymentCityContainer"
                      >
                        <Grid
                          item
                          className="JobHistoryDetails-EmploymentCityEditItem"
                        >
                          <Box className="JobHistoryDetails-EmploymentCityBox">
                            <TextField
                              className={clsx(
                                "JobHistoryDetails-EmploymentCityEdit",
                                "OHPTextField",
                                classes.textField
                              )}
                              id="employment_city"
                              disabled={jobHistoryId}
                              label="Employment City"
                              type="text"
                              value={jobHistory.employment_city || ""}
                              onChange={handleAttributeValueChange(
                                "employment_city"
                              )}
                            />
                          </Box>
                        </Grid>
                        {!jobHistoryId && (
                          <Grid
                            item
                            className="JobHistoryDetails-InstructionsItem"
                          >
                            <Typography className="JobHistoryDetails-InstructionsText">
                              City where your employment office was located (not
                              its headquarters)
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {jobHistoryId && (
                  <Grid
                    className={clsx(
                      "EmployeeRequestDetails-ActionsItem",
                      classes.actionButtonItem
                    )}
                  >
                    <VerificationStatusSelector
                      currentValue={jobHistory.employment_location_status}
                      onValueSelect={handleActionSelection(
                        "employment_location_status"
                      )}
                      recordStatus={jobHistory.status}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {(jobHistory.employment_location_status === "Disputed" ||
              jobHistory.employment_location_status === "Rejected") && (
              <Grid item>
                <Box className="JobHistoryDetails-CommentsBox">
                  <TextField
                    className={clsx(
                      "JobHistoryDetails-CommentsEdit",
                      "OHPTextField",
                      classes.textField
                    )}
                    disabled={
                      jobHistory.status !== "Submitted" &&
                      jobHistory.status !== "Submitter Input"
                    }
                    id="employment_location_comments"
                    label="Employment Location Comments"
                    multiline
                    rows={4}
                    type="text"
                    value={jobHistory.employment_location_comments || ""}
                    onChange={handleAttributeValueChange(
                      "employment_location_comments"
                    )}
                  />
                </Box>
              </Grid>
            )}
            <Divider
              className="JobHistoryDetails-HorizontalDivider"
              variant="middle"
              orientation="horizontal"
              flexItem={true}
            />
            <Grid item className="JobHistoryDetails-EmploymentLocationItem">
              <Grid container className="JobHistoryDetails-AttributeContainer">
                <Grid item className="JobHistoryDetails-LocationItemsItem">
                  <Grid item className="JobHistoryDetails-MultiItemsContainer">
                    <Grid
                      item
                      className="JobHistoryDetails-CompenstationTypeItem"
                    >
                      <Grid
                        container
                        className="JobHistoryDetails-CompenstationTypeContainer"
                      >
                        <Grid
                          item
                          className="JobHistoryDetails-CompenstationTypeSelectItem"
                        >
                          <FormControl
                            className={clsx(
                              "JobHistoryDetails-CompenstationTypeForm",
                              "OHPTextField",
                              classes.textField
                            )}
                          >
                            <InputLabel id="validate-position-compenstation-type-label">
                              Compensation Type
                            </InputLabel>
                            <Select
                              labelId="validate-position-compenstation-type-label"
                              id="Compenstation_type"
                              disabled={jobHistoryId}
                              value={
                                jobHistory.employment_compensation_type || ""
                              }
                              label="Compenstation Type"
                              onChange={handleAttributeValueChange(
                                "employment_compensation_type"
                              )}
                            >
                              <MenuItem key="Annually" value="Annually">
                                Annually
                              </MenuItem>
                              <MenuItem key="Hourly" value="Hourly">
                                Hourly
                              </MenuItem>
                              <MenuItem key="Weekly" value="Weekly">
                                Weekly
                              </MenuItem>
                              <MenuItem key="Bi-weekly" value="Bi-weekly">
                                Bi-weekly
                              </MenuItem>
                              <MenuItem key="Monthly" value="Monthly">
                                Monthly
                              </MenuItem>
                              <MenuItem key="Lump Sum" value="Lump Sum">
                                Lump Sum
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {!jobHistoryId && (
                          <Grid
                            item
                            className="JobHistoryDetails-InstructionsItem"
                          >
                            <Typography className="JobHistoryDetails-InstructionsText">
                              Select the way your were paid for the job
                              performed.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      className="JobHistoryDetails-CompenstationAmountItem"
                    >
                      <Grid
                        container
                        className="JobHistoryDetails-CompenstationAmountContainer"
                      >
                        <Grid
                          item
                          className="JobHistoryDetails-CompenstationAmountEditItem"
                        >
                          <Box className="JobHistoryDetails-CompenstationAmountBox">
                            <TextField
                              className={clsx(
                                "JobHistoryDetails-CompenstationAmountEdit",
                                "OHPTextField",
                                classes.textField
                              )}
                              id="employment_compensation_rate"
                              disabled={jobHistoryId}
                              label="Compensation Rate"
                              type="number"
                              value={
                                jobHistory.employment_compensation_rate || 0
                              }
                              onChange={handleAttributeValueChange(
                                "employment_compensation_rate"
                              )}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                            />
                          </Box>
                        </Grid>
                        {!jobHistoryId && (
                          <Grid
                            item
                            className="JobHistoryDetails-InstructionsItem"
                          >
                            <Typography className="JobHistoryDetails-InstructionsText">
                              Enter your compensation rate based on compensation
                              type you have selected.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {jobHistoryId && (
                  <Grid
                    className={clsx(
                      "EmployeeRequestDetails-ActionsItem",
                      classes.actionButtonItem
                    )}
                  >
                    <VerificationStatusSelector
                      currentValue={jobHistory.employment_compensation_status}
                      onValueSelect={handleActionSelection(
                        "employment_compensation_status"
                      )}
                      recordStatus={jobHistory.status}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {(jobHistory.employment_compensation_status === "Disputed" ||
              jobHistory.employment_compensation_status === "Rejected") && (
              <Grid item>
                <Box className="JobHistoryDetails-CommentsBox">
                  <TextField
                    className={clsx(
                      "JobHistoryDetails-CommentsEdit",
                      "OHPTextField",
                      classes.textField
                    )}
                    disabled={
                      jobHistory.status !== "Submitted" &&
                      jobHistory.status !== "Submitter Input"
                    }
                    id="employment_compensation_comments"
                    label="Employment Compensation Comments"
                    multiline
                    rows={4}
                    type="text"
                    value={jobHistory.employment_compensation_comments || ""}
                    onChange={handleAttributeValueChange(
                      "employment_compensation_comments"
                    )}
                  />
                </Box>
              </Grid>
            )}
            <Divider
              className="JobHistoryDetails-HorizontalDivider"
              variant="middle"
              orientation="horizontal"
              flexItem={true}
            />
            <Grid item className="JobHistoryDetails-JobResponsibilitiesItem">
              <Grid
                container
                className="JobHistoryDetails-JobResponsibilitiesContainer"
              >
                <Grid
                  item
                  className="JobHistoryDetails-JobResponsibilitiesEditItem"
                >
                  <Box className="JobHistoryDetails-JobResponsibilitiesBox">
                    <TextField
                      className={clsx(
                        "JobHistoryDetails-JobResponsibilitiesEdit",
                        "OHPTextField",
                        classes.textField
                      )}
                      id="employment_responsibilities"
                      disabled={jobHistoryId}
                      label="Job Responsibilities"
                      multiline
                      rows={4}
                      type="text"
                      value={jobHistory.employment_responsibilities || ""}
                      onChange={handleAttributeValueChange(
                        "employment_responsibilities"
                      )}
                    />
                  </Box>
                </Grid>
                {jobHistoryId ? (
                  <Grid
                    className={clsx(
                      "EmployeeRequestDetails-ActionsItem",
                      classes.actionButtonItem
                    )}
                  >
                    <VerificationStatusSelector
                      currentValue={
                        jobHistory.employment_responsibilities_status
                      }
                      onValueSelect={handleActionSelection(
                        "employment_responsibilities_status"
                      )}
                      recordStatus={jobHistory.status}
                    />
                  </Grid>
                ) : (
                  <Grid item className="JobHistoryDetails-InstructionsItem">
                    <Typography className="JobHistoryDetails-InstructionsText">
                      Please provide short description of your job
                      responsibilites. Your input is limited to 500 characters.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {(jobHistory.employment_responsibilities_status === "Disputed" ||
              jobHistory.employment_responsibilities_status === "Rejected") && (
              <Grid item>
                <Box className="JobHistoryDetails-CommentsBox">
                  <TextField
                    className={clsx(
                      "JobHistoryDetails-CommentsEdit",
                      "OHPTextField",
                      classes.textField
                    )}
                    disabled={
                      jobHistory.status !== "Submitted" &&
                      jobHistory.status !== "Submitter Input"
                    }
                    id="employment_responsibilities_comments"
                    label="Job Responsibilities Comments"
                    multiline
                    rows={4}
                    type="text"
                    value={
                      jobHistory.employment_responsibilities_comments || ""
                    }
                    onChange={handleAttributeValueChange(
                      "employment_responsibilities_comments"
                    )}
                  />
                </Box>
              </Grid>
            )}

            <Grid item className="JobHistoryDetails-ActionsItem">
              <Grid container className="JobHistoryDetails-ActionsContainer">
                {calculateAvailableAction() === "Approve" && (
                  <Grid item className="JobHistoryDetails-SubmitItem">
                    <Button
                      color="primary"
                      variant="contained"
                      className="JobHistoryDetails-ApproveButton"
                      onClick={onApprove}
                    >
                      Approve
                    </Button>
                  </Grid>
                )}
                {calculateAvailableAction() === "Reject" && (
                  <Grid item className="JobHistoryDetails-SubmitItem">
                    <Button
                      color="primary"
                      variant="contained"
                      className="JobHistoryDetails-RejectButton"
                      onClick={onReject}
                    >
                      Reject
                    </Button>
                  </Grid>
                )}
                {calculateAvailableAction() === "Submit" && (
                  <Grid item className="JobHistoryDetails-SubmitItem">
                    <Button
                      color="primary"
                      variant="contained"
                      className="JobHistoryDetails-SubmitButton"
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                )}
                <Grid item className="JobHistoryDetails-CancelItem">
                  <Button
                    color="primary"
                    variant="outlined"
                    className="JobHistoryDetails-CancelButton"
                    href={jobHistoryId ? "/jobhistory" : "/"}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

JobHistoryDetails.propTypes = {
  httpService: PropTypes.object.isRequired,
  displayMessage: PropTypes.func.isRequired,
  employeeProfile: PropTypes.object.isRequired,
  maxCount: PropTypes.number,
};
