import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { logDebug } from "paf-utils-loggers";

import JobHistoryRecord from "./JobHistoryRecord";
import JobHistorySummary from "./JobHistorySummary";
import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  verticalDivider: {
    display: "none",
    "@media (min-width: 940px)": {
      display: "flex",
    },
  },
}));

export default function JobHistory(props) {
  logDebug(`In JobHistory with props: ${JSON.stringify(props)}`);

  const classes = useStyles();

  const { httpService, displayMessage, setDisplaySpinner } = props;
  const [jobHistoryRecords, setJobHistoryRecords] = React.useState([]);
  const [loadRejected, setLoadRejected] = React.useState(false);

  // API URL
  const getJobHistoryURL = "/api/employee/jobhistory";

  ///////////////////////
  //// API callbacks ////
  ///////////////////////
  const onJobHistoryGetSuccess = (data) => {
    if (!loadRejected) {
      setJobHistoryRecords(
        data.data.filter(
          (job) => job.status !== "Rejected" && job.status !== "Cancelled"
        )
      );
    } else {
      setJobHistoryRecords(data.data);
    }
  };
  const onJobHistoryGetError = (message, data) => {
    if (displayMessage) {
      displayMessage("error", message);
    }
  };
  ///////////////////////////
  //// END API callbacks ////
  ///////////////////////////
  ///////////////////
  //// API calls ////
  ///////////////////
  // load Job History
  const loadJobHistory = () => {
    httpService.get(
      getJobHistoryURL,
      onJobHistoryGetSuccess,
      onJobHistoryGetError,
      setDisplaySpinner
    );
  };
  ///////////////////////
  //// END API calls ////
  ///////////////////////

  const toggleView = (jobId) => (event) => {
    let index = jobHistoryRecords.findIndex(
      (jobRecord) => jobRecord.id === jobId
    );
    let jobs = JSON.parse(JSON.stringify(jobHistoryRecords));
    jobs[index].expanded_view = !jobs[index].expanded_view;
    setJobHistoryRecords(jobs);
  };
  const handleChange = (event) => {
    setLoadRejected(event.target.checked);
  };

  useEffect(() => {
    loadJobHistory();
  }, [loadRejected]);

  return (
    <div className="JobHistory-PageBody">
      <Grid container className="JobHistory-Container">
        <Grid item className="JobHistory-ListItem">
          <Grid container className="JobHistory-ListContainer">
            <Grid item className="JobHistory-ListTitleItem">
              <Grid container className="JobHistory-TitleContainer">
                <Grid item className="JobHistory-TitleTextItem">
                  <Typography className="JobHistory-ListTitleText">
                    My Job History
                  </Typography>
                </Grid>
                <Grid item className="JobHistory-LoadRejectedToggleItem">
                  <Typography className="JobHistory-LoadRejectedToggleText">
                    Include Rejected and Cancelled
                  </Typography>
                  <Checkbox
                    size="small"
                    onChange={handleChange}
                    checked={loadRejected}
                  />
                </Grid>
              </Grid>
              <Divider className="OHPDivider" />
            </Grid>
            <Grid item className="JobHistory-ListJobsItem">
              <Grid container className="JobHistory-ListJobsContainer">
                {jobHistoryRecords.map((record) => {
                  return (
                    <Grid container className="JobHistory-JobItem">
                      <JobHistoryRecord
                        {...props}
                        record={record}
                        onToggleView={toggleView}
                      />
                      <Divider
                        className="JobHistory-HorizontalDivider"
                        variant="middle"
                        orientation="horizontal"
                        flexItem={true}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          className={clsx(
            classes.verticalDivider,
            "JobHistory-VerticalDivider"
          )}
          variant="middle"
          flexItem={true}
          orientation="vertical"
        />
        <Grid item className="JobHistory-ShortListItem">
          <Grid container className="JobHistory-ShortListContainer">
            <Grid item className="JobHistory-ShortListTitleItem">
              <Typography className="JobHistory-ShortListTitleText">
                Short List
              </Typography>
              <Divider className="OHPDivider" />
            </Grid>
            <Grid item className="JobHistory-ShortListJobsItem">
              <Grid container className="JobHistory-ShortListJobsContainer">
                {jobHistoryRecords.map((record) => {
                  return (
                    <div>
                      <JobHistorySummary
                        {...props}
                        record={record}
                        onToggleView={toggleView}
                      />
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

JobHistory.propTypes = {
  httpService: PropTypes.object.isRequired,
  displayMessage: PropTypes.func.isRequired,
};
