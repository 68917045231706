import React from "react";
import clsx from "clsx";

import PropTypes from "prop-types";
import ErrorIcon from "@material-ui/icons/Error";
import CheckIcon from "@material-ui/icons/Check";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CancelIcon from "@mui/icons-material/Cancel";
import FeedbackIcon from "@mui/icons-material/Feedback";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { logDebug } from "paf-utils-loggers";
import { Tooltip } from "@material-ui/core";

export default function JobHistorySummary(props) {
  logDebug(`In JobHistorySummary with props: ${JSON.stringify(props)}`);

  return (
    <Grid item className="JobHistorySummary-Body">
      <Grid container className="JobHistorySummary-Container">
        <Grid item className="JobHistorySummary-StatusIconItem">
          {props.record.status === "Submitted" && (
            <Tooltip title="Submitted to Verifier">
              <PendingActionsIcon className="JobHistoryRecord-PendingIcon" />
            </Tooltip>
          )}
          {props.record.status === "Approved" && (
            <Tooltip title="Position Approved by Verifier">
              <CheckIcon className="JobHistoryRecord-VerifiedIcon" />
            </Tooltip>
          )}
          {props.record.status === "Rejected" && (
            <Tooltip title="Position Rejected by Verifier">
              <ErrorIcon className="JobHistoryRecord-VerificationFailedIcon" />
            </Tooltip>
          )}
          {props.record.status === "Cancelled" && (
            <Tooltip title="Position Cancelled by Employee">
              <CancelIcon className="JobHistoryRecord-VerificationCancelledIcon" />
            </Tooltip>
          )}
          {props.record.status === "Submitter Input" && (
            <Tooltip title="Submitted Feedback to Verifier">
              <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
            </Tooltip>
          )}
          {props.record.status === "Verifier Input" && (
            <Tooltip title="Received Feedback from Verifier">
              <QuestionAnswerIcon className="JobHistoryRecord-DisputedIcon" />
            </Tooltip>
          )}
        </Grid>
        <Grid item className="JobHistorySummary-SummaryItem">
          <Grid container className="JobHistorySummary-SummaryContainer">
            <Grid item className="JobHistorySummary-EmployerShortNameItem">
              <Typography
                className="JobHistorySummary-EmployerShortNameText"
                //</Grid>className={clsx(
                //  "JobHistorySummary-EmployerShortNameText",
                //  props.record.verified_percent !== 100
                //</Grid>    ? "JobHistorySummary-EmployerShortNameTextUnverified"
                //    : ""
                //)}
              >
                {props.record.employer_short_name
                  ? props.record.employer_short_name
                  : props.record.employer_name}
              </Typography>
            </Grid>
            <Grid item className="JobHistorySummary-TitleItem">
              <Typography
                className="JobHistorySummary-TitleText"
                //</Grid>className={clsx(
                //  "JobHistorySummary-TitleText",
                //  props.record.verified_percent !== 100
                //</Grid>    ? "JobHistorySummary-EmployerShortNameTextUnverified"
                //    : ""
                //)}
              >
                {props.record.employment_title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

JobHistorySummary.propTypes = {
  record: PropTypes.object.isRequired,
};
